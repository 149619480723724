import React from 'react';

import deleteAnimation from '../../../../assets/images/trash.json';
import exitAnimation from '../../../../assets/images/door.json';
import faxAnimation from '../../../../assets/images/fax.json';
import successAnimation from '../../../../assets/images/success.json';
import errorAnimation from '../../../../assets/images/extramation.json';
import LottieComponent from '../LottieComponent';

import styles from './index.module.scss';

interface AnimationProps {
  name: 'DELETE' | 'EXIT' | 'FAX' | 'FAX_SUCCESS' | 'FAX_FAILED';
}

const Animation: React.FC<AnimationProps> = (props: AnimationProps) => {
  const getData = (
    name: 'DELETE' | 'EXIT' | 'FAX' | 'FAX_SUCCESS' | 'FAX_FAILED'
  ) => {
    switch (name) {
      case 'EXIT':
        return exitAnimation;
      case 'FAX_SUCCESS':
        return successAnimation;
      case 'FAX_FAILED':
        return errorAnimation;
      case 'FAX':
        return faxAnimation;
      default:
        return deleteAnimation;
    }
  };
  return (
    <LottieComponent
      animationData={getData(props.name)}
      className={styles.deleteAni}
    />
  );
};

export default Animation;
