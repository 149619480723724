import { ReduxAction } from '../../reduxHelper';

export const SET_RESERVATION_DETAIL_FROM_SCHEDULE =
  'SET_RESERVATION_DETAIL_FROM_SCHEDULE';
export const REMOVE_RESERVATION_DETAIL_FROM_SCHEDULE =
  'REMOVE_RESERVATION_DETAIL_FROM_SCHEDULE';

export function setFromSchedule(url: string): ReduxAction<string> {
  return {
    type: SET_RESERVATION_DETAIL_FROM_SCHEDULE,
    payload: url,
  };
}

export function removeFromSchedule() {
  return {
    type: SET_RESERVATION_DETAIL_FROM_SCHEDULE,
  };
}
