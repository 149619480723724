import React, { useEffect, useState } from 'react';

import { useWindowSize } from 'react-use';
import { useLocation } from 'react-router-dom';

import CommonFormRowLayout from '../../../system/components/CommonFormRowLayout';
import VenusFormInput from '../../../system/components/VenusFormInput';
import VenusFormSelect, {
  Option,
} from '../../../system/components/VenusFormSelect';

import {
  UserInfoWithOperationAuth,
  Position,
  Organization,
} from '../../../../api/user';

import { ROUTE_USER_INFO_REGISTER } from '../../../../utils/constants';

import Messages from '../../../../utils/messages';
import { isMobileMode, isNotEmptyString } from '../../../../utils/helper';

import styles from './index.module.scss';

export interface UserEditFormEditableFields {
  emailAddress: boolean;
  familyName: boolean;
  givenName: boolean;
  executiveCode: boolean;
  orgInCharge: boolean;
  mainPosition: boolean;
  phone: boolean;
}

interface UserFormItemMap {
  emailAddress?: string;
  familyName?: string;
  givenName?: string;
  executiveCode?: string;
  orgInCharge?: string;
  mainPosition?: string;
  phone?: string;
}

export interface UserFormErrorMsg extends UserFormItemMap {}

export const UserFormIdMap: UserFormItemMap = {
  emailAddress: 'user-edit-form-mail',
  familyName: 'user-edit-form-familyName',
  givenName: 'user-edit-form-givenName',
  executiveCode: 'user-edit-form-executiveCode',
  orgInCharge: 'user-edit-form-orgInCharge',
  mainPosition: 'user-edit-form-mainPosition',
  phone: 'user-edit-form-phone',
};

interface EditFormProps {
  user: UserInfoWithOperationAuth;
  editableFields: UserEditFormEditableFields;
  errorMsg?: UserFormErrorMsg;
  onMailChange: (mail: string) => void;
  onFamilyNameChange: (familyName: string) => void;
  onGivenNameChange: (givenName: string) => void;
  onExecutiveCodeChange: (executiveCode: string) => void;
  onPhoneChange: (tel: string) => void;
  onOrgInChargeCodeChange: (id: string) => void;
  onMainPositionCodeChange: (id: string) => void;
}

const UserEditForm: React.FC<EditFormProps> = (props: EditFormProps) => {
  const { user, editableFields, errorMsg } = props;
  const [positions, setPositions] = useState<Position[]>([]);
  const { width } = useWindowSize();
  const location = useLocation();
  const isRegister = location.pathname === ROUTE_USER_INFO_REGISTER;
  useEffect(() => {
    if (!user.orgInChargeCode || user.orgInChargeCode === '') {
      setPositions([]);
      return;
    }
    if (user.orgsBelongTo) {
      const target = user.orgsBelongTo.find((item: Organization) => {
        return item.orgId === user.orgInChargeCode;
      });
      if (target) {
        setPositions(target.positions);
      } else {
        setPositions([]);
      }
    }
  }, [user.orgInChargeCode]);

  const hasExecutiveCode = () => {
    return isNotEmptyString(user.executiveCode);
  };

  const hasStaffCode = () => {
    return isNotEmptyString(user.staffCode);
  };

  return (
    <div className={styles.userEditFormWrapper}>
      <CommonFormRowLayout
        id={UserFormIdMap.emailAddress}
        label={Messages['user.mail']}
        editable={editableFields.emailAddress}
        labelSpanOfPC={5}
        labelSpanOfMobile={24}
        errorMsg={errorMsg?.emailAddress}
      >
        {editableFields.emailAddress && (
          <VenusFormInput
            value={user.emailAddress}
            error={isNotEmptyString(errorMsg?.emailAddress)}
            onChange={props.onMailChange}
            className={styles.editableItem}
          />
        )}
        {!editableFields.emailAddress && (
          <div className={styles.itemContent}>{user.emailAddress}</div>
        )}
      </CommonFormRowLayout>

      <CommonFormRowLayout
        id={UserFormIdMap.familyName}
        label={Messages['user.familyName']}
        editable={editableFields.familyName}
        mustInput
        errorMsg={errorMsg?.familyName}
      >
        {editableFields.familyName && (
          <VenusFormInput
            value={user.familyName}
            error={isNotEmptyString(errorMsg?.familyName)}
            onChange={props.onFamilyNameChange}
            className={styles.editableItem}
          />
        )}
        {!editableFields.familyName && (
          <div className={styles.itemContent}>{user.familyName}</div>
        )}
      </CommonFormRowLayout>

      <CommonFormRowLayout
        id={UserFormIdMap.givenName}
        label={Messages['user.givenName']}
        editable={editableFields.givenName}
        mustInput
        errorMsg={errorMsg?.givenName}
      >
        {editableFields.givenName && (
          <VenusFormInput
            value={user.givenName}
            error={isNotEmptyString(errorMsg?.givenName)}
            onChange={props.onGivenNameChange}
            className={styles.editableItem}
          />
        )}
        {!editableFields.givenName && (
          <div className={styles.itemContent}>{user.givenName}</div>
        )}
      </CommonFormRowLayout>

      <CommonFormRowLayout
        id={UserFormIdMap.executiveCode}
        label={Messages['user.executiveCode']}
        editable={editableFields.executiveCode}
        labelSpanOfPC={5}
        labelSpanOfMobile={24}
        errorMsg={errorMsg?.executiveCode}
      >
        {editableFields.executiveCode && (
          <VenusFormInput
            value={user.executiveCode}
            error={isNotEmptyString(errorMsg?.executiveCode)}
            onChange={props.onExecutiveCodeChange}
            className={styles.editableItem}
          />
        )}
        {!editableFields.executiveCode && (
          <div className={styles.itemContent}>{user.executiveCode}</div>
        )}
      </CommonFormRowLayout>

      <CommonFormRowLayout
        id={UserFormIdMap.phone}
        label={Messages['user.phone.number']}
        editable={editableFields.phone}
        labelSpanOfPC={5}
        labelSpanOfMobile={24}
        errorMsg={errorMsg?.phone}
        mustInput
      >
        {editableFields.phone && (
          <>
            <VenusFormInput
              value={user.tel}
              error={isNotEmptyString(errorMsg?.phone)}
              onChange={props.onPhoneChange}
              className={styles.editableItem}
            />
            <div className={styles.hintWrapper}>
              <div className={styles.hint}>
                {Messages['user.phone.number.hint1']}
              </div>
              <div className={styles.hint}>
                {Messages['user.phone.number.hint2']}
              </div>
            </div>
          </>
        )}
        {!editableFields.phone && (
          <>
            <div className={styles.itemContent}>{user.tel}</div>
            <div className={styles.hintWrapper}>
              <div className={styles.hint}>
                {Messages['user.phone.number.hint1']}
              </div>
              <div className={styles.hint}>
                {Messages['user.phone.number.hint2']}
              </div>
            </div>
          </>
        )}
      </CommonFormRowLayout>

      {hasExecutiveCode() && (
        <CommonFormRowLayout
          id={UserFormIdMap.orgInCharge}
          label={Messages['user.orgInCharge']}
          editable={editableFields.orgInCharge}
          labelSpanOfMobile={24}
          errorMsg={errorMsg?.orgInCharge}
          mustInput={!hasStaffCode()}
        >
          {editableFields.orgInCharge && (
            <VenusFormSelect
              style={{ width: isMobileMode(width) ? '100%' : 480 }}
              value={user.orgInChargeCode}
              onChange={props.onOrgInChargeCodeChange}
              placeholder={Messages['user.list.orgInCharge.select.placeholder']}
            >
              <Option value="">
                {Messages['user.list.orgInCharge.select.placeholder']}
              </Option>
              {user.orgsBelongTo &&
                user.orgsBelongTo.map((p) => {
                  return <Option value={p.orgId}>{p.orgName}</Option>;
                })}
            </VenusFormSelect>
          )}
          {!editableFields.orgInCharge && (
            <div className={styles.itemContent}>{user.orgInChargeName}</div>
          )}
        </CommonFormRowLayout>
      )}

      {hasExecutiveCode() && (
        <CommonFormRowLayout
          id={UserFormIdMap.mainPosition}
          label={Messages['user.mainPosition']}
          editable={editableFields.mainPosition}
          labelSpanOfMobile={24}
          errorMsg={errorMsg?.mainPosition}
          mustInput={!hasStaffCode()}
        >
          {editableFields.mainPosition && (
            <VenusFormSelect
              style={{ width: isMobileMode(width) ? '100%' : 480 }}
              value={user.mainPositionCode}
              onChange={props.onMainPositionCodeChange}
              placeholder={Messages['user.list.mainPositon.select.placeholder']}
            >
              <Option value="">
                {Messages['user.list.mainPositon.select.placeholder']}
              </Option>

              {positions &&
                positions.map((p) => {
                  return <Option value={p.positionId}>{p.positionName}</Option>;
                })}
            </VenusFormSelect>
          )}
          {!editableFields.mainPosition && (
            <div className={styles.itemContent}>{user.mainPositionName}</div>
          )}
        </CommonFormRowLayout>
      )}
    </div>
  );
};

export default UserEditForm;
