import { ReduxAction } from '../../reduxHelper';
import * as Action from '../../actions/UserInfo';
import {
  UserInfoWithOperationAuth,
  generateDefaultUserInfo,
} from '../../../api/user';

const defaultState: UserInfoWithOperationAuth = generateDefaultUserInfo();

const userInfoReducer = (
  state = defaultState,
  action: ReduxAction<UserInfoWithOperationAuth>
): UserInfoWithOperationAuth => {
  switch (action.type) {
    case Action.SET_USER_INFO:
      return action.payload || defaultState;
    case Action.REMOVE_USER_INFO:
      return defaultState;
    default:
      return state;
  }
};

export const refreshUserInfoFlag = (
  state = false,
  action: ReduxAction<boolean>
): boolean => {
  switch (action.type) {
    case Action.REFRESH_USER_INFO_FLAG:
      return true;
    case Action.REMOVE_REFRESH_USER_INFO_FLAG:
      return false;
    default:
      return state;
  }
};

export default userInfoReducer;
