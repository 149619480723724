export interface ErrorInstance {
  title: string;
  detail: string;
  returnToTop: boolean;
}
interface ErrorMsg {
  [prop: string]: ErrorInstance;
}
export const Errors: ErrorMsg = {
  '400': {
    title: '不正な入力です。',
    detail: '不正な入力です。処理を続行することができません。',
    returnToTop: true,
  },
  '403': {
    title: '操作する権限がありません。',
    detail: '操作を実行できません。権限を制限された可能性があります。',
    returnToTop: true,
  },
  '404': {
    title: 'ページまたはデータが存在しません。',
    detail:
      'ご指定のURLは間違っているか変更された可能性があります。または、操作しようとしたデータが削除された可能性があります。',
    returnToTop: true,
  },
  '409': {
    title: '他のユーザーによりデータが更新された可能性があります。',
    detail: '他のユーザーによりデータが更新された可能性があります。',
    returnToTop: true,
  },
  '416': {
    title: '範囲外を指定しています。',
    detail: '範囲外を指定しています。処理を続行することができません。',
    returnToTop: true,
  },
  '419': {
    title: 'リクエストの信頼性を確認できませんでした。',
    detail:
      'リクエストの信頼性を確認できないため、処理を続行することができません。',
    returnToTop: true,
  },
  saml: {
    title: '認証でシステムエラーが発生しました。',
    detail: '時間をおいて再度お試しください。',
    returnToTop: false,
  },
  systemError: {
    title: 'ただいまご利用になれません。',
    detail: '時間をおいて再度お試しください。',
    returnToTop: true,
  },
};

export const ReturnToTopMsg = {
  hint: 'トップ画面へ戻って再度作業をやり直してください。',
  btn: 'Topへ戻る',
};

const getErrorMsg = (reason: string): ErrorInstance => {
  if (!reason) {
    return Errors.systemError;
  }
  const instance = Errors[reason];
  if (instance) {
    return instance;
  }
  return Errors.systemError;
};

export default getErrorMsg;

export interface ServiceUnavailable {
  message?: string | null;
  description1?: string | null;
  description2?: string | null;
}

export interface UserInfoNotEnough {
  message?: string | null;
  description?: string | null;
  executiveCodeUrl?: string | null;
}
