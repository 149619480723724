import React from 'react';

import styles from './index.module.scss';

export interface CommonHeaderProps {
  title: React.ReactElement;
  leftOperation?: React.ReactElement;
  rightOperation?: React.ReactElement;
  zIndex: number;
}

const CommonHeader: React.FC<CommonHeaderProps> = (
  props: CommonHeaderProps
) => {
  const { title, leftOperation, rightOperation } = props;
  return (
    <div
      className={styles.commonHeaderWrapper}
      style={{ zIndex: props.zIndex }}
    >
      <div className={styles.sideLeft}>{leftOperation}</div>
      {title}
      <div className={styles.sideRight}>{rightOperation}</div>
    </div>
  );
};

export default CommonHeader;
