import * as Action from '../../actions/DataList';

const EMPTY_DATA_STATE: Action.DataListState = {
  pageName: '',
  toSearch: true,
  result: null,
  scrollPos: 0,
  condition: null,
  conditionBase: null,
  page: { limit: 20, offset: 0, totalCount: 0 },
};

export const defaultState = EMPTY_DATA_STATE;

const dataListReducer = (
  state = defaultState,
  action: any
): Action.DataListState => {
  switch (action.type) {
    case Action.SET_DO_SEARCH_FLAG: {
      if (action.toSearch) {
        const nextState = JSON.parse(JSON.stringify(defaultState));
        nextState.toSearch = true;
        return nextState;
      }
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.toSearch = false;
      return nextState;
    }
    case Action.RESET_SEARCH_DATA_RESULTS: {
      return JSON.parse(JSON.stringify(defaultState));
    }
    case Action.SET_SCROLL_POS: {
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.scrollPos = action.scrollPos;
      return nextState;
    }
    case Action.SET_SEARCH_CONDITION: {
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.condition = action.condition;
      return nextState;
    }
    case Action.SET_SEARCH_CONDITION_BASE: {
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.conditionBase = action.conditionBase;
      return nextState;
    }
    case Action.SEARCH_DATA_SUCCESS: {
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.isFetching = false;
      nextState.page = action.page;
      if (action.result) {
        if (nextState.result && nextState.result.length > 0) {
          nextState.result = nextState.result.concat(action.result);
        } else {
          nextState.result = action.result;
        }
      }
      return nextState;
    }
    case Action.SET_DATALIST_NAME: {
      const nextState = JSON.parse(JSON.stringify(state));
      nextState.pageName = action.name || '';
      return nextState;
    }
    default:
      return state;
  }
};

export default dataListReducer;
