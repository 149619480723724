export interface MessagesInterface {
  [key: string]: string;
}
const Messages: MessagesInterface = {
  'system.symbol.colon.full': '：',
  'system.symbol.colon.half': ':',
  'alert.update.success': '保存が完了しました',
  'alert.delete.success': '削除が完了しました',
  'alert.revoke.success': '取り消しが完了しました',
  'alert.reserve.success': '予約が完了しました',
  'alert.reserve.update.success': '予約が変更しました',
  'alert.reserve.cancel.success': '予約を取り消しました',
  'management.commom.alert.noaction': 'この操作は取り消せません。',
  'management.commom.operator.create': '新規登録する',
  'management.commom.operator.search': '検索',
  'management.commom.operator.filter.hint': '検索条件',
  'management.commom.operator.search.full': '検索する',
  'management.commom.operator.edit.full': '編集する',
  'management.commom.operator.copy.full': 'コピーする',
  'management.commom.operator.select': '選択',
  'management.commom.operator.delete': '削除する',
  'management.commom.operator.cancel': 'キャンセル',
  'management.commom.operator.confirm': '確認する',
  'management.commom.operator.revoke': '取り消す',
  'management.commom.operator.complete.select': '完了する',
  'management.commom.operator.goback': '< 戻る',
  'management.commom.operator.goback2': '戻る',
  'management.commom.operator.save': '保存する',
  'management.commom.operator.register': '登録',
  'management.commom.operator.close': '閉じる',
  'management.commom.operator.set': '設定する',
  'management.commom.operator.return.to.edit': '編集に戻る',
  'management.commom.validator.mustinput': '必須',
  'management.commom.insert.item': '新たに追加する',
  'management.commom.meeting.name': '会合名',
  'management.commom.prefecture': '都道府県',
  'management.commom.date': '日付',
  'management.commom.colon': '：',
  'management.commom.bracket': '（{}）',
  'management.commom.operator.return.top': 'Topに戻る',
  'management.commom.operator.logout': 'ログアウト',
  'management.commom.message.logout': 'ログアウトしますか?',
  'management.existed.item': '登録済み',
  'management.commom.operator.sent.fax': '{}の予約をFax送信します',
  'management.commom.operator.sent.fax.success': 'Faxが送信されました',
  'management.commom.operator.sent.fax.failed': 'Faxの送信に失敗しました',
  'management.commom.operator.sent.fax.failed.hint':
    'SOKAオンラインサポートセンターに連絡してください',
  'management.commom.operator.sent': '送信する',
  'management.unit.list.search.placeholder': 'ユニット名・組織名・会館名を入力',
  'management.unit.name': 'ユニット名',
  'management.unit.orgs': '登録組織',
  'management.unit.managers': 'ユニット管理者',
  'management.unit.venues': 'ユニット利用会館',
  'management.unit.common.venues': '登録会館',
  'management.unit.venues.add': '登録会館追加',
  'management.unit.primary.venue.label': '管理対象',
  'management.unit.primary.venue': '管理会館',
  'management.unit.primary.veune.disable': '他ユニットで管理中',
  'management.unit.primary.rsv.confirmers': '会場予約確認者',
  'management.unit.release.date': '会館予約開放日',
  'management.unit.release.date.content': '毎月{}日',
  'management.unit.release.limit': '開放期限',
  'management.unit.release.limit.content': 'ヶ月',
  'management.unit.list.mobile.omit': '他{}件',
  'management.unit.release.date.month': '毎月',
  'management.unit.release.day': '日',
  'management.unit.release.month.end': '末',
  'management.unit.release.last.day.checkbox': '末日に設定する',
  'management.unit.for.manager': '(会館管理者向け)',
  'management.unit.participantType.title.placeholder': '部名を入力してください',
  'management.unit.participantType.names': '部・グループ',
  'management.unit.participantType.delete': '部・グループの削除',
  'management.unit.list.page.name': 'ユニット管理',
  'management.unit.list.prefecture.select.placeholder': '都道府県を選択',
  'management.unit.detail.page.name': 'ユニット詳細',
  'management.unit.detail.user.hint':
    'ユーザー名をクリックすると、ユーザーの管理権限の編集ができます。',
  'management.unit.detail.participantType.names.hint1':
    'ユニット内で使用できる部・グループを追加します。',
  'management.unit.detail.participantType.names.hint2':
    'その他の部・グループに追加されます。',
  'management.unit.detail.participantType.names.link':
    '全国共通の部・グループを確認する',
  'management.unit.edit.page.name': 'ユニット編集',
  'management.unit.edit.selected.orgs': '選択済み組織',
  'management.unit.edit.org.selector.page.name': '組織追加',
  'management.unit.edit.org.selector.goback.parent': '上位組織に戻る',
  'management.unit.edit.org.selector.search.cond.clear': 'クリア',
  'management.unit.edit.org.selector.search.placeholder':
    '組織名を入力してください',
  'management.unit.alert.delete': 'ユニット「%s」を削除してもよろしいですか？',
  'management.unit.org.alert.delete':
    '登録組織「%s」を削除してもよろしいですか？',
  'management.unit.venue.alert.delete':
    'ユニット利用会館「%s」を削除してもよろしいですか？',
  'management.unit.unit.admin.alert.delete':
    'ユニット管理者「%s」を削除してもよろしいですか？',
  'management.unit.rsvconfirmer.admin.alert.delete':
    '会場予約確認者「%s」を削除してもよろしいですか？',
  'management.unit.participant.alert.delete':
    '部・グループ「%s」を削除してもよろしいですか？',
  'management.venue.list.page.name': '会館管理',
  'management.venue.list.search.placeholder': '会館名を入力',
  'management.venue.list.unit.select.placeholder': 'ユニットを選択',
  'management.venue.list.mobile.search.placeholder': '検索条件',
  'management.venue.name': '会館名',
  'management.venue.room': '会場名',
  'management.venue.admin': '会館管理者',
  'management.venue.manage.unit': '管理ユニット',
  'management.venue.alert.delete': '会館「%s」を削除してもよろしいですか？',
  'management.venue.admin.alert.delete':
    '会館管理者「%s」を削除してもよろしいですか？',
  'management.master.list.title': '会場備品マスタ',
  'management.master.list.equip.placeholder': '備品名を入力',
  'management.master.participant.title': '部・グループ予約マスタ',
  'management.master.meeting.title': '会合種別マスタ',
  'management.master.meeting.item.title.placehoder': '会合種別を入力',
  'management.master.meeting.item.sub.placehoder': '会合名を入力',
  'management.master.MeetingType.delete': '会合種別の削除',
  'management.master.list.add': '追加',
  'management.master.list.item1': '数字入力',
  'management.master.list.item2': '有無',
  'management.master.list.placehoder': '部・グループ名を入力',
  'management.master.list.error.repeat': '名称が重複しています',
  'management.master.list.error.tooLong': '200文字以下で入力してください',
  'management.master.participantType.title.placeholder':
    '部・グループ分類名を入力',
  'management.master.participantType.delete': '部・グループの削除',
  'management.inform.unit': 'ユニット',
  'management.inform.list.title': '連絡事項一覧',
  'management.inform.create.page.name': '連絡事項新規登録',
  'management.inform.edit.title': '連絡事項編集',
  'management.inform.detail.title': '連絡事項詳細',
  'management.inform.edit.confirm.title': '連絡事項確認',
  'management.inform.edit.sendto.all': '全国',
  'management.inform.edit.send.name': '管理者',
  'management.inform.list.informType1': '全国連絡',
  'management.inform.list.informType2': '都道府県連絡',
  'management.inform.list.informType3': 'ユニット連絡',
  'management.inform.list.informSendto': '発信範囲',
  'management.inform.list.informSendto2': '発信者先',
  'management.inform.list.informSendto3': '発信先',
  'management.inform.list.informTitle': 'タイトル',
  'management.inform.list.informDetail': '本文',
  'management.inform.list.startDate': '発信日',
  'management.inform.list.endDate': '掲載期限日',
  'management.inform.list.senderRoleName': '発信者',
  'management.inform.list.unit.select.placeholder': 'ユニットを選択',
  'management.inform.list.prefecture.select.placeholder': '都道府県を選択',
  'management.inform.edit.errorMsg.title.requiryInput': '必須入力項目です',
  'management.inform.edit.errorMsg.title.textNum':
    '200文字以下で入力してください',
  'management.inform.edit.errorMsg.detail.requiryInput': '必須入力項目です',
  'management.inform.edit.errorMsg.detail.textNum':
    '2000文字以下で入力してください',
  'management.inform.edit.errorMsg.startDate.requiryInput': '必須入力項目です',
  'management.inform.edit.errorMsg.endDate.requiryInput': '必須入力項目です',
  'management.inform.edit.errorMsg.dateComparision':
    '発信日は掲載期限日より前でなければなりません',
  'management.systemAdmin.alert.delete':
    'システム管理者から「%s」を削除してもよろしいですか？',
  'management.user.selected.user': '選択済みユーザ',
  'management.user.email.address': 'SOKA-ID（メールアドレス）',
  'management.systemAdmin.list.page.name': 'システム管理者',
  'management.systemAdmin.modal.title': 'システム管理者追加',
  'management.systemAdmin.delete': '削除',
  'management.inform.alert.delete': '連絡事項を削除してもよろしいですか？',
  'management.prefectureAdmin.modal.title': '都道府県管理者追加',
  'management.prefectureAdmin.alert.delete':
    '都道府県管理者から「%s」を削除してもよろしいですか？',
  'management.prefectureAdmin.modal.search.title.prefecture': '管理都道府県',
  'management.prefectureAdmin.modal.prefecture.hint':
    '都道府県管理者一覧で選択した都道府県が反映されます',
  'management.admin.authorities.edit': '個人設定編集',
  'management.user.field.modified': '対象が変更されています',
  'user.soka.id': 'SOKA-ID',
  'user.name': '名前',
  'user.staff.code': '職員コード',
  'user.staff.id': '職員番号',
  'user.main.position.name': '代表役職',
  'user.soka.email': '(メールアドレス)',
  'user.mail': 'メールアドレス',
  'user.familyName': '姓',
  'user.givenName': '名',
  'user.executiveCode': '幹部コード',
  'user.orgInCharge': '担当組織',
  'user.mainPosition': '役職',
  'user.phone.number': '電話番号',
  'user.register.page.name': '利用者情報登録',
  'user.edit.page.name': 'アカウント設定',
  'user.phone.number.hint1': '※半角数字で、ハイフンをつけずに入力してください',
  'user.phone.number.hint2':
    '※携帯電話番号または自宅の電話番号を入力してください',
  'user.orgnization': '所属組織',
  'user.list.orgInCharge.select.placeholder': '担当組織を選択',
  'user.list.mainPositon.select.placeholder': '役職を選択',
  'management.user.under.manage.units': 'ユニット管理対象',
  'management.user.under.manage.venues': '会館管理対象',
  'management.user.under.manage.resv.units': '会場予約確認対象',
  'management.unit.create.page.name': 'ユニット新規登録',
  'management.unit.add.admin.placeholder': '幹部コードを入力',
  'management.unit.add.admin.not.found': '存在しない・選択済み幹部コードです',
  'management.unit.orgs.upper.folded': '上位階層を表示',
  'management.unit.orgs.upper.opened': '上位階層を省略',
  'management.unit.orgs.upper.connector': '＞',
  'management.unit.orgs.search.totalCount': '件数:{}件',
  'management.reservation.list.search.placeholder':
    '会館名、会場名、申請者名を入力',
  'management.reserve.title.detail': '予約詳細',
  'management.reserve.title.edit': '予約編集',
  'management.reserve.title.confirm': '予約確認',
  'management.reserve.title.list': '予約一覧',
  'management.reserve.title.create': '会場予約',
  'management.reserve.date': '予約日付',
  'management.reserve.time': '予約時間',
  'management.reserve.time.start': '会場利用開始時刻',
  'management.reserve.time.end': '会場利用終了時刻',
  'management.reserve.time.event': '会合開始時刻',
  'management.reserve.org.name': '開催組織',
  'management.reserve.participanttypename': '部・グループ',
  'management.reserve.participanttypename.select': '部・グループを選択',
  'management.reserve.meetingname': '会合種別',
  'management.reserve.meetingname.select': '会合種別を選択',
  'management.reserve.meetingtypename': '部・グループ',
  'management.reserve.participantname': '申請者',
  'management.reserve.update.user': '最終更新者',
  'management.reserve.participantcount': '参加予定人数',
  'management.reserve.digit.people.a': '人',
  'management.reserve.digit.people.b': '名',
  'management.reserve.capasity': '定員:{}名',
  'management.reserve.digit.things': '個',
  'management.reserve.equipments': '備品',
  'management.reserve.error.startend':
    '会場利用終了時刻は開始時刻よりあとに入力してください',
  'management.reserve.error.over.3h': '利用時間は3時間以内としてください',
  'management.reserve.error.starttime':
    '会合開始時刻は会場利用時間内で入力してください',
  'management.reserve.error.requirnumber': '数値で入力してください',
  'management.reserve.error.capacityover': '参加予定人数が定員を上回っています',
  'management.reserve.error.isminus': 'マイナスの値は入れることができません',
  'management.prefectureAdmin.list.page.name': '都道府県管理者',
  'input.check.must.input': '必須入力項目です',
  'input.check.error.requirnumber': '数値で入力してください',
  'input.check.length.over.limit': '200文字以下で入力してください',
  'input.check.day.of.month.over.range': '1-31の半角数字で入力してください',
  'input.check.duplicate.participantTypes': '部・グループ名が重複しています',
  'sidemenu.unit': 'ユニット管理',
  'sidemenu.venue': '会館管理',
  'sidemenu.infom': '連絡事項一覧',
  'sidemenu.reservation': '予約一覧',
  'sidemenu.master': 'マスタ管理',
  'sidemenu.admin': '管理者設定',
  'top.inform.more': 'その他連絡が{}件あります',
  'top.btn.to.reservation.list': '予約一覧へ',
  'top.btn.to.schedule.table': '空き状況確認',
  'top.return.btn': 'TOPに戻る',
  'top.venue.select.placeholder': '会館を選択',
  'schdule.page.name': '空き状況確認画面',
  'schdule.reservation.my': '自分の予約',
  'schdule.reservation.other': '予約あり',
  'schdule.reservation.other.with.placeholder': '予約あり　',
  'schdule.room.butsuma': '礼拝室・法話室',
  'schdule.root.kaigi': '会議室',
  'schdule.date.next': '翌日',
  'schdule.date.previous': '前日',
  'schdule.return.page.top': 'Topに戻る',
  'schdule.send.fax': 'Faxを送信する',
  'schdule.print.schedule': '印刷する',
  'schdule.operator.reserve': '予約',
  'reservation.organaizaion.select.keyword,placeholder':
    '組織名、全国、方面を入力',
  'reservation.organaizaion.select.page.name': '開催組織選択',
  'reservation.alert.delete': '予約を取り消してもよろしいですか？',
  'reservation.alert.delete.subtitle':
    '一度取り消された予約は、もとに戻すことができません。',
  'management.venue.view.page.name': '会館詳細',
  'management.venue.create.page.name': '会館新規登録',
  'management.venue.edit.page.name': '会館編集',
  'management.venue.confirm.page.name': '会館編集確認',
  'management.venue.form.item.name': '会館名',
  'management.venue.form.item.name.placeholder': '会館名を入力',
  'management.venue.form.item.fax': 'Fax番号',
  'management.venue.form.item.fax.placeholder': 'Fax番号を入力',
  'management.venue.form.item.room.detail': '会場詳細',
  'management.venue.form.item.room.edit': '会場編集',
  'management.venue.form.item.room.confirm': '会場編集確認',
  'management.venue.form.item.room': '会場',
  'management.venue.form.item.room.name': '会場名',
  'management.venue.form.item.room.name.placeholder': '会場名を入力',
  'management.venue.form.item.room.type': '種類',
  'management.venue.form.item.room.capcity': '定員',
  'management.venue.form.item.room.notification': '予約通知',
  'management.venue.form.item.room.manager.only': '管理者用',
  'management.venue.form.item.room.equips': '備品',
  'management.venue.form.item.room.roomType.kaigi': '会議室',
  'management.venue.form.item.room.roomType.butsuma': '礼拝室・法話室',
  'management.venue.form.units': '所属ユニット',
  'management.venue.form.units.primary': '管理',
  'management.venue.form.item.room.notification.send': '通知する',
  'management.venue.form.item.room.notification.send.dont': '通知しない',
  'management.venue.form.item.room.equip.message1': '※備品数は半角数字で入力',
  'management.venue.form.item.room.equip.alert.delete':
    '備品「%s」を削除してもよろしいですか？',
  'management.venue.form.item.room.errorMsg.number': '数値で入力してください',
  'management.venue.form.item.room.errorMsg.input1': '必須入力項目です',
  'management.venue.form.item.room.errorMsg.input2':
    '200文字以下で入力してください',
  'management.venue.form.item.room.errorMsg.count.over.range':
    '数値が大きすぎます',
  'management.venue.form.item.room.alert.delete':
    '会場から「%s」を削除してもよろしいですか？',
  'management.venue.form.item.day.of.year.alert.delete':
    '日付指定「%s」を削除してもよろしいですか？',
  'management.venue.form.item.room.errorMsg.repeat': '名称が重複しています',
  'management.venue.form.available.times': '利用可能日・時間',
  'management.venue.form.available.times.hint':
    '利用可能日・時間は次回の会場予約解放日時に反映されます。',
  'management.venue.form.available.week': '曜日指定',
  'management.venue.form.available.date': '日付指定',
  'management.venue.form.available.date.hint':
    '日付指定した日のみ曜日の繰り返しから除外されます。',
  'management.venue.form.available.date.page.name': '特定の利用可否選択',
  'management.venue.form.available.week.page.name': 'の利用可否選択',
  'management.venue.form.available.flag': '利用可否選択',
  'management.venue.form.irregular.type': '指定範囲',
  'management.venue.form.irregular.day': '日時指定',
  'management.venue.form.irregular.date.empty': '日付設定なし',
  'management.venue.form.irregular.year': '期間指定',
  'management.venue.form.available.flag.true': '利用可',
  'management.venue.form.available.flag.false': '利用不可',
  'management.venue.form.available.time': '利用可能時間',
  'management.venue.form.available.time.start': '開始',
  'management.venue.form.available.time.end': '終了',
  'management.venue.fax.hint': '※半角数字で、ハイフンをつけずに入力',
  'common.week.day.1': '月曜日',
  'common.week.day.2': '火曜日',
  'common.week.day.3': '水曜日',
  'common.week.day.4': '木曜日',
  'common.week.day.5': '金曜日',
  'common.week.day.6': '土曜日',
  'common.week.day.7': '日曜日',
  'common.week.day.8': '祝日',
  'common.empty.list': '登録された情報はありません',
  'common.list.page.do.search.hint': '検索条件を指定してください',
  'error.available.time.end.over.start':
    '開始時刻は終了時刻より前を設定してください',
  'error.available.time.duration.override': '利用可能時間が重複しています',
  'error.available.time.duration.mustinput': '利用可能時間が必須項目です',
  'error.room.capcity': '1以上9999以下の数値を入力してください',
  'error.available.date.end.over.start':
    '開始日は終了日より前を設定してください',
  'error.available.date.duplicate': '重複しています(利用可能日)',
  'hint.should.input.number': '※半角数字で入力',
  'hint.should.input.number.room.capcity': '※定員は半角数字で入力',
  'empty.reservation.my': '予約はまだありません',
  'error.user.info.not.enough.link.executive.code': '幹部コード紐づけ',
  'hint.allow.cookie': 'Cookieを有効にしてください',
  'time.over.range': '無効',
  'error.available.time.over.range': '無効な時刻',
};

export const filledByParam = (key: string, param: any): string => {
  const msg = Messages[key];
  if (!msg) {
    return '';
  }
  return msg.replace('{}', `${param}`);
};

export default Messages;
