import { ReduxAction } from '../../reduxHelper';
import * as Action from '../../actions/ReservationDetail';

const defaultState = '';

const reservationDetailFrom = (
  state = defaultState,
  action: ReduxAction<string>
): string => {
  switch (action.type) {
    case Action.SET_RESERVATION_DETAIL_FROM_SCHEDULE:
      return action.payload || '';
    case Action.REMOVE_RESERVATION_DETAIL_FROM_SCHEDULE:
      return '';
    default:
      return state;
  }
};

export default reservationDetailFrom;
