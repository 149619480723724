import { useReducer } from 'react';

import { CommonError, ResponseBody } from '../../fetch';
import useCatchCommonFetchError from '../useCatchCommonFetchError';

export interface FetchState<P = any> {
  isFetching: boolean;
  error?: any;
  data?: P;
}

export interface RequestParams {
  url: string;
  data?: any;
  method?: 'put' | 'delete' | 'post';
}

export type FetchFn = <T = any>(
  url: string,
  ...args: any[]
) => Promise<ResponseBody<T>>;

interface DataFetchReducer<P> {
  (state: FetchState<P>, action: Action): FetchState<P>;
}

export function createDataFetchReducer<P = any>(): DataFetchReducer<P> {
  return (state: FetchState<P>, action: Action): FetchState<P> => {
    switch (action.type) {
      case 'FETCH_INIT': {
        const deepCopy = JSON.parse(JSON.stringify(state));
        return { ...deepCopy, isFetching: true, error: undefined };
      }
      case 'FETCH_SUCCESS': {
        return { data: action.payload, isFetching: false, error: undefined };
      }
      case 'FETCH_FAILURE': {
        return { isFetching: false, error: action.error };
      }
      default: {
        const deepCopy = JSON.parse(JSON.stringify(state));
        return { ...deepCopy, isFetching: false, error: undefined };
      }
    }
  };
}
export type ACTION_TYPE = 'FETCH_INIT' | 'FETCH_SUCCESS' | 'FETCH_FAILURE';
export const UPDATE_ACTION_SUCCESS = 'updateActionSuccess';
export interface Action<T = any> {
  type: ACTION_TYPE;
  payload?: T;
  error?: CommonError;
}

export default function useFetch<T = any>(
  fn: FetchFn,
  defaultData?: T
): [FetchState<T>, (params: RequestParams) => Promise<void>] {
  const dataFetchReducer = createDataFetchReducer<T>();
  const defaultState: FetchState<T> = {
    isFetching: false,
    error: undefined,
    data: defaultData,
  };
  const [state, dispatch] = useReducer(dataFetchReducer, defaultState);
  const handleCommonFetchError = useCatchCommonFetchError();

  const fetch = async (params: RequestParams): Promise<void> => {
    dispatch({ type: 'FETCH_INIT' });
    try {
      let result = await fn<T>(params.url, params.data);

      if (defaultData) {
        result = Object.assign(defaultData, result.data);
        dispatch({
          type: 'FETCH_SUCCESS',
          payload: params.method !== undefined ? UPDATE_ACTION_SUCCESS : result,
        });
      } else {
        dispatch({
          type: 'FETCH_SUCCESS',
          payload:
            params.method !== undefined ? UPDATE_ACTION_SUCCESS : result.data,
        });
      }
    } catch (e) {
      const callback = (e: any) => {
        dispatch({ type: 'FETCH_FAILURE', error: e });
      };
      handleCommonFetchError(e, callback);
    }
  };
  return [state, fetch];
}
