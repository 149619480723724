import React, { useContext } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { LayoutContext } from '../../CommonLayout';

import styles from './index.module.scss';

interface LoadingProps {
  withoutCommonLayout?: boolean;
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 56, top: 'calc(50% - 50px)' }} spin />
);

const Loading: React.FC<LoadingProps> = (props: LoadingProps) => {
  const layout = useContext(LayoutContext);
  const { withoutCommonLayout } = props;
  let wrapperClassName = styles.loadingWrapper;
  if (layout === 'collapsedSideMenu' || withoutCommonLayout) {
    wrapperClassName = `${wrapperClassName} ${styles.positionCenter}`;
  } else {
    wrapperClassName = `${wrapperClassName} ${styles.positionInRightPart}`;
  }

  return (
    <div className={wrapperClassName}>
      <Spin indicator={antIcon} />
    </div>
  );
};
export default Loading;
