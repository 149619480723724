import React, { useState, useEffect } from 'react';
import * as H from 'history';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Menu } from 'antd';

import * as DataListAction from '../../../../utils/actions/DataList';

import {
  ROUTE_MANAGE_UNIT_LIST,
  ROUTE_MANAGE_UNIT_DETAIL,
  ROUTE_MANAGE_UNIT_EDIT,
  ROUTE_MANAGE_SYSTEM_ADMIN_LIST,
  ROUTE_MANAGE_RESERVATION_DETAIL,
  ROUTE_MANAGE_VENUE_LIST,
  ROUTE_MANAGE_VENUE_EDIT,
  ROUTE_MANAGE_VENUE_DETAIL,
  ROUTE_MANAGE_INFORM_LIST,
  ROUTE_MANAGE_PREFECTURE_ADMIN_LIST,
  ROUTE_VENUE_EQUIP_LIST,
  ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST,
  ROUTE_MANAGE_RESERVATION,
  ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST,
} from '../../../../utils/constants';

import { SideMenu as SideMenuAuth } from '../../../../api/menu';

import {
  CommonIndexPropInterface,
  isMobileMode,
} from '../../../../utils/helper';

import { ReactComponent as UnitIcon } from '../../../../assets/images/sitemap-solid.svg';
import { ReactComponent as VenueIcon } from '../../../../assets/images/venue.svg';
import { ReactComponent as InformIcon } from '../../../../assets/images/bullhorn.svg';
import { ReactComponent as ReservationIcon } from '../../../../assets/images/calendar-check.svg';
import { ReactComponent as MasterIcon } from '../../../../assets/images/book.svg';
import { ReactComponent as AdminIcon } from '../../../../assets/images/ellipsis-v-solid.svg';
import Messages from '../../../../utils/messages';

import styles from './index.module.scss';

const { SubMenu } = Menu;
export interface SideMenuProps extends SideMenuAuth {
  collapsed: boolean;
  history: H.History;
  onClickCallback?: () => void;
}

const IconTitleMap: CommonIndexPropInterface = {
  unit: {
    title: Messages['sidemenu.unit'],
    icon: <UnitIcon />,
  },
  venue: {
    title: Messages['sidemenu.venue'],
    icon: <VenueIcon />,
  },
  inform: {
    title: Messages['sidemenu.infom'],
    icon: <InformIcon />,
  },
  reservation: {
    title: Messages['sidemenu.reservation'],
    icon: <ReservationIcon />,
  },
  master: {
    title: Messages['sidemenu.master'],
    icon: <MasterIcon />,
  },
  Admin: {
    title: Messages['sidemenu.admin'],
    icon: <AdminIcon />,
  },
};

const getAllOpenSubMenuKeys = (props: SideMenuProps): string[] => {
  const keys: string[] = [];
  if (props.reservation) {
    keys.push('subReservation');
  }
  if (props.master) {
    keys.push('subReservationMaster');
  }
  if (props.systemAdm || props.prefAdm) {
    keys.push('subManager');
  }
  return keys;
};

const SideMenu: React.FC<SideMenuProps> = (props: SideMenuProps) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  useEffect(() => {
    const getOpenKeys = getAllOpenSubMenuKeys(props);
    if (props.collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(getOpenKeys);
    }
  }, [props]);

  const { collapsed, systemAdm, prefAdm, master } = props;

  const handleOnOpenChange = (): void => {
    setOpenKeys(getAllOpenSubMenuKeys(props));
  };

  const wrapperClassName = collapsed
    ? `${styles.sideMenuWrapper} ${styles.sideMenuCollapsedWrapper}`
    : styles.sideMenuWrapper;

  const setSelectedKeys = (): string[] => {
    const result: string[] = [];
    const currentPath = window.location.pathname;
    if (
      currentPath.indexOf(ROUTE_MANAGE_UNIT_LIST) !== -1 ||
      currentPath.indexOf(ROUTE_MANAGE_UNIT_DETAIL) !== -1 ||
      currentPath.indexOf(ROUTE_MANAGE_UNIT_EDIT) !== -1
    ) {
      result.push('unit');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_SYSTEM_ADMIN_LIST) !== -1) {
      result.push('systemAdmin');
      return result;
    }
    if (currentPath.indexOf(ROUTE_MANAGE_PREFECTURE_ADMIN_LIST) !== -1) {
      result.push('stateAdmin');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_RESERVATION_DETAIL) !== -1) {
      result.push('resDetail');
      return result;
    }

    if (
      currentPath.indexOf(ROUTE_MANAGE_VENUE_LIST) !== -1 ||
      currentPath.indexOf(ROUTE_MANAGE_VENUE_DETAIL) !== -1 ||
      currentPath.indexOf(ROUTE_MANAGE_VENUE_EDIT) !== -1
    ) {
      result.push('venue');
      return result;
    }

    if (currentPath.indexOf(ROUTE_VENUE_EQUIP_LIST) !== -1) {
      result.push('equip');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST) !== -1) {
      result.push('participant');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST) !== -1) {
      result.push('meeting');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_INFORM_LIST) !== -1) {
      result.push('inform');
      return result;
    }

    if (currentPath.indexOf(ROUTE_MANAGE_RESERVATION) !== -1) {
      result.push('reseration');
      return result;
    }

    return result;
  };

  const onMenuSelectOrClick = (info: any): void => {
    dispatch(DataListAction.resetDataList());
    const itemKey = info.key;
    let url = ROUTE_MANAGE_UNIT_LIST;
    switch (itemKey) {
      case 'unit':
        url = ROUTE_MANAGE_UNIT_LIST;
        break;
      case 'systemAdmin':
        url = ROUTE_MANAGE_SYSTEM_ADMIN_LIST;
        break;
      case 'stateAdmin':
        url = ROUTE_MANAGE_PREFECTURE_ADMIN_LIST;
        break;
      case 'resDetail':
        url = ROUTE_MANAGE_RESERVATION_DETAIL;
        break;
      case 'venue':
        url = ROUTE_MANAGE_VENUE_LIST;
        break;
      case 'inform':
        url = ROUTE_MANAGE_INFORM_LIST;
        break;
      case 'equip':
        url = ROUTE_VENUE_EQUIP_LIST;
        break;
      case 'reseration':
        url = ROUTE_MANAGE_RESERVATION;
        break;
      case 'participant':
        url = ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST;
        break;
      case 'meeting':
        url = ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST;
        break;
      default:
    }
    props.history.push(url);
    if (props.onClickCallback) {
      props.onClickCallback();
    }
  };

  const generateMenuContent = (key: string): React.ReactNode => {
    const { title, icon } = IconTitleMap[key];
    return (
      <span className={styles.itemWrapper}>
        <span className={styles.itemTitleIcon}>{icon}</span>
        <span className={styles.itemTitleContent}>{title}</span>
      </span>
    );
  };

  const getInlineCollapsedValue = (): boolean => {
    if (!collapsed) {
      return false;
    }
    if (collapsed && !isMobileMode(width)) {
      return true;
    }
    return false;
  };
  return (
    <div className={wrapperClassName}>
      <Menu
        mode="inline"
        onOpenChange={handleOnOpenChange}
        openKeys={openKeys}
        selectedKeys={setSelectedKeys()}
        triggerSubMenuAction="click"
        inlineCollapsed={getInlineCollapsedValue()}
        expandIcon={<span />}
        subMenuCloseDelay={0}
        onSelect={onMenuSelectOrClick}
        onClick={onMenuSelectOrClick}
      >
        {props.unit && (
          <Menu.Item key="unit">{generateMenuContent('unit')}</Menu.Item>
        )}
        {props.venue && (
          <Menu.Item key="venue">{generateMenuContent('venue')}</Menu.Item>
        )}
        {props.inform && (
          <Menu.Item key="inform">{generateMenuContent('inform')}</Menu.Item>
        )}
        {props.reservation && (
          <Menu.Item key="reseration">
            {generateMenuContent('reservation')}
          </Menu.Item>
        )}
        {master && (
          <SubMenu
            key="subReservationMaster"
            title={generateMenuContent('master')}
          >
            {!collapsed && (
              <>
                <Menu.Item key="participant">
                  <span className={styles.itemContent}>部・グループ</span>
                </Menu.Item>
                <Menu.Item key="meeting">
                  <span className={styles.itemContent}>会合種別</span>
                </Menu.Item>
                <Menu.Item key="equip">
                  <span className={styles.itemContent}>会場備品</span>
                </Menu.Item>
              </>
            )}
          </SubMenu>
        )}
        {(systemAdm || prefAdm) && (
          <SubMenu key="subManager" title={generateMenuContent('Admin')}>
            {systemAdm && !collapsed && (
              <Menu.Item key="systemAdmin">
                <span className={styles.itemContent}>システム管理者</span>
              </Menu.Item>
            )}
            {prefAdm && !collapsed && (
              <Menu.Item key="stateAdmin">
                <span className={styles.itemContent}>都道府県管理者</span>
              </Menu.Item>
            )}
          </SubMenu>
        )}
      </Menu>
    </div>
  );
};

export default SideMenu;
