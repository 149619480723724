import React from 'react';
import { useMount } from 'react-use';
import lottie from 'lottie-web/build/player/lottie_light'; // import sizeをコントロールするため

interface LottieProperties {
  animationData?: any;
  className?: string;
  style?: React.CSSProperties;
}

const LottieComponent: React.FC<LottieProperties> = (
  props: LottieProperties
) => {
  const lottieContainerRef = React.useRef<HTMLDivElement>(null);

  useMount(() => {
    lottie.loadAnimation({
      container: lottieContainerRef.current || document.createElement('div'),
      animationData: props.animationData,
    });
  });

  return (
    <div
      ref={lottieContainerRef}
      className={props.className}
      style={props.style}
    />
  );
};

export default LottieComponent;
