import React from 'react';

import { useWindowSize } from 'react-use';

import VenusBtn from '../../components/VenusBtn';

import { isMobileMode } from '../../../../utils/helper';

import styles from './index.module.scss';

export interface OperationBtnProps {
  type: 'primary' | 'secondary' | 'goback';
  title: string;
  onClick: () => void;
}

interface CommonPageContentContainerProps {
  pageTitle?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  scrollRef?: any;
  contentType?: 'list' | 'content';
  btns?: OperationBtnProps[];
  noFix?: boolean;
}

const CommonPageContentContainer: React.FC<CommonPageContentContainerProps> = (
  props: CommonPageContentContainerProps
) => {
  const { width } = useWindowSize();
  const {
    pageTitle,
    children,
    style,
    scrollRef,
    contentType,
    btns,
    noFix,
  } = props;
  const titleMarginBottom = {
    marginBottom: '2rem',
  };

  if (contentType === 'list' && isMobileMode(width)) {
    titleMarginBottom.marginBottom = '1rem';
  }

  const getTargetBtn = (
    type: 'primary' | 'secondary' | 'goback'
  ): OperationBtnProps[] | undefined => {
    if (!btns || btns.length === 0) {
      return undefined;
    }
    return btns.filter((b) => b.type === type);
  };

  const secondaryBtns = getTargetBtn('secondary');
  const primaryBtns = getTargetBtn('primary');
  const gobackBtns = getTargetBtn('goback');

  const wrapperStyle: React.CSSProperties = {
    paddingBottom: '3rem',
  };

  if (isMobileMode(width)) {
    if (noFix) {
      wrapperStyle.paddingBottom = `0`;
    } else {
      wrapperStyle.paddingBottom = '1rem';

      if (secondaryBtns || primaryBtns || gobackBtns) {
        let height = 64;
        if (primaryBtns) {
          height += 36 * primaryBtns.length;
        }
        if (secondaryBtns) {
          height += 44 * secondaryBtns.length;
        }
        if (gobackBtns) {
          height += 46 * gobackBtns.length;
        }
        wrapperStyle.paddingBottom = `${height}px`;
      }
    }
  }
  Object.assign(wrapperStyle, style);
  return (
    <div
      className={styles.contentWrapper}
      ref={scrollRef}
      style={wrapperStyle}
      id="CommonPageContentContainer"
    >
      {pageTitle && (
        <div
          className={styles.pageTitle}
          role="heading"
          aria-level={1}
          style={titleMarginBottom}
        >
          {pageTitle}
        </div>
      )}
      {children}
      {btns && btns.length > 0 && (
        <div
          className={
            noFix
              ? `${styles.btnWrapper} ${styles.btnWrapperNoFix}`
              : styles.btnWrapper
          }
        >
          {!isMobileMode(width) &&
            secondaryBtns &&
            secondaryBtns.map((btn) => {
              return (
                <VenusBtn className={styles.secondaryBtn} onClick={btn.onClick}>
                  <span>{btn.title}</span>
                </VenusBtn>
              );
            })}
          {primaryBtns &&
            primaryBtns.map((btn) => {
              return (
                <VenusBtn
                  type="gradient"
                  width={isMobileMode(width) ? '100%' : undefined}
                  onClick={btn.onClick}
                >
                  <span>{btn.title}</span>
                </VenusBtn>
              );
            })}
          {isMobileMode(width) &&
            secondaryBtns &&
            secondaryBtns.map((btn) => {
              return (
                <VenusBtn
                  className={styles.secondaryBtn}
                  onClick={btn.onClick}
                  width={isMobileMode(width) ? '100%' : undefined}
                >
                  <span>{btn.title}</span>
                </VenusBtn>
              );
            })}
          {gobackBtns && gobackBtns.length > 0 && (
            <div className={styles.gobackBtnWrapper}>
              {gobackBtns.map((btn) => {
                return (
                  <VenusBtn
                    type="link"
                    onClick={btn.onClick}
                    className={styles.goback}
                    width={isMobileMode(width) ? '100%' : undefined}
                  >
                    {btn.title}
                  </VenusBtn>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CommonPageContentContainer;
