import React from 'react';
import { useWindowSize } from 'react-use';

import VenusBtn from '../VenusBtn';
import VenusModal from '../VenusModal';
import Messages from '../../../../utils/messages';
import Animation from '../Animation';
import { isMobileMode } from '../../../../utils/helper';

import styles from './index.module.scss';

interface WarningModalProps {
  setModalVisible: (modileVisible: false) => void;
  visible?: boolean;
  confirmSetting: (id: string) => void;
  id: string;
  message?: string;
  subTitle?: string;
  hideGraphics?: boolean;
  operationName?: string;
  hideWarning?: boolean;
  modalWrapperClassName?: string;
  modalBodyClassName?: string;
  withoutMinHeight?: boolean;
  animationName?: 'DELETE' | 'EXIT' | 'FAX';
}
const WarningModal: React.FC<WarningModalProps> = (
  props: WarningModalProps
) => {
  const { width } = useWindowSize();

  const handleModalClose = () => {
    props.setModalVisible(false);
  };

  const handleConfirm = () => {
    const { confirmSetting, id } = props;
    if (confirmSetting) {
      confirmSetting(id);
    }
    handleModalClose();
  };

  const operatorBtn = (
    <VenusBtn
      key="confirm"
      type={props.animationName === 'FAX' ? 'gradient' : 'remind'}
      onClick={handleConfirm}
      className={styles.confirmBtn}
    >
      {props.operationName
        ? props.operationName
        : Messages['management.commom.operator.delete']}
    </VenusBtn>
  );
  const modalFooter = (
    <div className={styles.btnWrapper} data-testid={`${props.id}-btn-wrapper`}>
      {isMobileMode(width) && operatorBtn}
      <VenusBtn onClick={handleModalClose} className={styles.cancelBtn}>
        {Messages['management.commom.operator.cancel']}
      </VenusBtn>
      {!isMobileMode(width) && operatorBtn}
    </div>
  );

  const getBodyClassName = () => {
    let className = props.withoutMinHeight
      ? styles.modalBody
      : `${styles.modalBody} ${styles.bodyMinHeight}`;
    if (props.modalBodyClassName) {
      className = `${className} ${props.modalBodyClassName}`;
    }
    return className;
  };

  return (
    <VenusModal
      wrapClassName={
        props.modalWrapperClassName
          ? `${styles.modalWrapper} ${props.modalWrapperClassName}`
          : styles.modalWrapper
      }
      width={isMobileMode(width) ? '80%' : '26rem'}
      visible={props.visible}
      title={null}
      maskClosable={false}
      onCancel={handleModalClose}
      footer={modalFooter}
    >
      <div className={getBodyClassName()}>
        <div className={styles.contentWrapper}>
          {!props.hideGraphics && (
            <div className={styles.graphics}>
              <Animation name={props.animationName || 'DELETE'} />
            </div>
          )}
          <div className={styles.messageArea}>
            {props.message && (
              <div className={styles.title}>{props.message}</div>
            )}
            {!props.hideWarning && (
              <div className={styles.subTitle}>
                {props.subTitle
                  ? props.subTitle
                  : Messages['management.commom.alert.noaction']}
              </div>
            )}
          </div>
        </div>
      </div>
    </VenusModal>
  );
};

export default WarningModal;
