import moment from 'moment';

import {
  MOBILE_SWITCH_WIDTH,
  DATE_YMDW_VIEW_FORMAT,
  DATE_YMD_VALUE_FORMAT,
} from './constants';

moment.updateLocale('ja', {
  weekdays: ['日', '月', '火', '水', '木', '金', '土'],
});

export interface CommonIndexPropInterface {
  [key: string]: any;
}

export const isMobileMode = (width: number): boolean => {
  return width <= MOBILE_SWITCH_WIDTH;
};

export const getCurrentWidthWhenConstract = (): number => {
  return window.innerWidth;
};

export const isMobileWhenConstract = (): boolean => {
  return window.innerWidth <= MOBILE_SWITCH_WIDTH;
};

interface QueryParams {
  [key: string]: any;
}

export const generateURLParams = (url: string, params?: QueryParams) => {
  if (!params) {
    return url;
  }
  const keys = Object.keys(params);
  if (!keys || keys.length === 0) {
    return url;
  }

  let paramStr = '';
  for (let i = 0; i < keys.length; i++) {
    const value = params[keys[i]];
    if (typeof value !== 'undefined' && value !== null) {
      paramStr = `${paramStr}${keys[i]}=${params[keys[i]]}&`;
    }
  }
  paramStr = paramStr.substring(0, paramStr.length - 1);

  if (paramStr) {
    return `${url}?${paramStr}`;
  }
  return url;
};

export const generateNextPageCond = (current: {
  limit: string | number;
  offset: string | number;
}): { limit: number; offset: number } => {
  const result = {
    limit: 20,
    offset: 0,
  };
  if (typeof current.limit === 'string') {
    const parseLimit = parseInt(current.limit, 10);
    if (!Number.isNaN(parseLimit)) {
      result.limit = parseLimit;
    }
  } else {
    result.limit = current.limit;
  }
  if (typeof current.offset === 'string') {
    const parseOffset = parseInt(current.offset, 10);
    if (!Number.isNaN(parseOffset)) {
      result.offset = parseOffset + result.limit;
    }
  } else {
    result.offset = current.offset + result.limit;
  }
  return result;
};

export const uniqueStringArray = (array: Array<string>): Array<string> => {
  return Array.from(new Set(array));
};

export const scrollToTargetElementOfPageTop = (
  anchorId: string | undefined
): void => {
  if (anchorId) {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      window.scrollTo(0, anchorElement.offsetTop - 50);
    }
  }
};

export const scrollToTargetElement = (anchorId: string | undefined): void => {
  if (anchorId) {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
};

export const scrollWindowToSpecifiedPos = (pos: any) => {
  window.scrollTo(0, pos);
};

export const scrollToTargetElementInModal = (
  anchorId: string | undefined
): void => {
  if (anchorId) {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }
};

export function deleteItemFromArray<T = any>(
  array: T[] | undefined | null,
  fn: (item: T) => boolean
): T[] {
  if (!array) {
    return [];
  }
  const copy: T[] = JSON.parse(JSON.stringify(array));
  const index = copy.findIndex(fn);
  if (index >= 0) {
    copy.splice(index, 1);
  }
  return copy;
}

export function formatViewDate(
  date: string | undefined | null,
  format?: string
): string {
  const currentFormat = format || DATE_YMDW_VIEW_FORMAT;
  if (!date) {
    return moment().format(currentFormat);
  }
  try {
    return moment(date).format(currentFormat);
  } catch (e) {
    return moment().format(currentFormat);
  }
}

export function parsetStringToInt(value?: string | null | number): number {
  if (!value) {
    return 0;
  }
  if (typeof value === 'number') {
    return value;
  }
  const parseValue = parseInt(value, 10);
  if (!Number.isNaN(parseValue)) {
    return parseValue;
  }
  return 0;
}

export function isNotEmptyString(input: undefined | null | string): boolean {
  if (typeof input === 'undefined') {
    return false;
  }
  if (input === null) {
    return false;
  }
  if (input.length < 1) {
    return false;
  }
  return true;
}

/**
 * 指定日からoffset dayを指定する
 * @param days offsetDays
 * @param originDate 座標Date なし場合、nowから
 * @param format 座標Dateのフォーマット なし場合 yyyy-MM-dd
 * @returns
 */
export function getSpecifiedDaysLaterDate(days: number, originDate?: string) {
  if (!originDate) {
    return moment(moment().format(DATE_YMD_VALUE_FORMAT))
      .add(days, 'days')
      .format(DATE_YMD_VALUE_FORMAT);
  }

  const parseFormat = DATE_YMD_VALUE_FORMAT;

  try {
    const originMoment = moment(originDate, parseFormat);
    return originMoment.add(days, 'days').format(DATE_YMD_VALUE_FORMAT);
  } catch (e) {
    return moment(moment().format(DATE_YMD_VALUE_FORMAT))
      .add(days, 'days')
      .format(DATE_YMD_VALUE_FORMAT);
  }
}

export function getNowDate(): string {
  return moment().format(DATE_YMD_VALUE_FORMAT);
}

export function validateTel(val: string) {
  return /^\d+$/.test(val);
}

export function notEmptyObject(input: any): boolean {
  return input !== undefined && input !== null;
}

export function isContainedByArray(target: string, array?: string[]): boolean {
  if (!array || array.length === 0) {
    return false;
  }
  return array.indexOf(target) !== -1;
}

export const outReserveInVenueLimit = (
  date: moment.Moment,
  availableDateFrom: string | null,
  availableDateTo: string | null
): boolean => {
  const current = moment(date.format(DATE_YMD_VALUE_FORMAT));
  if (availableDateFrom && availableDateTo) {
    const start = moment(availableDateFrom);
    const end = moment(availableDateTo);
    return current.isBefore(start) || current.isAfter(end);
  }
  if (availableDateFrom) {
    const start = moment(availableDateFrom);
    return current.isBefore(start);
  }
  if (availableDateTo) {
    const end = moment(availableDateTo);
    return current.isAfter(end);
  }
  return false;
};

export function isNumber(val: string | null | undefined | number) {
  if (val === undefined || val === null) {
    return false;
  }
  if (typeof val === 'number') {
    return true;
  }
  return /^\d+$/.test(val);
}

export function overflowHiddenWhenMastShow() {
  const bodyList = document.getElementsByTagName('body');
  const htmlList = document.getElementsByTagName('html');
  if (htmlList && htmlList.length > 0 && bodyList && bodyList.length > 0) {
    htmlList[0].style.overflow = 'hidden';
    bodyList[0].style.overflow = 'hidden';
  }
}

export function overflowUnsetWhenMastShow() {
  const bodyList = document.getElementsByTagName('body');
  const htmlList = document.getElementsByTagName('html');
  if (htmlList && htmlList.length > 0 && bodyList && bodyList.length > 0) {
    htmlList[0].style.overflow = '';
    bodyList[0].style.overflow = '';
  }
}
