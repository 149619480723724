import { useHistory } from 'react-router-dom';

import {
  ROUTE_USER_INFO_REGISTER,
  ROUTE_ERROR_PAGE,
  LOGIN_REDIRECT_TO,
} from '../../constants';

export default function useCatchCommonFetchError(): (
  e: any,
  callback?: (e: any) => void
) => void {
  const history = useHistory();

  return (e: any, callback?: (e: any) => void) => {
    const { response } = e;
    const commonErrorCodes = [400, 403, 404, 409, 416, 419, 428, 503];
    if (response && commonErrorCodes.includes(response.status)) {
      history.push(`${ROUTE_ERROR_PAGE}/${response.status}`);
    } else if (response && response.status === 401) {
      const redirectTo = history.location.search
        ? `${history.location.pathname}${history.location.search}`
        : history.location.pathname;
      localStorage.setItem(LOGIN_REDIRECT_TO, redirectTo);
      const contentType = response.headers['content-type'];
      if (contentType.indexOf('text/html') !== -1) {
        const formStr = response.data as string;
        const div = document.createElement('div');
        div.innerHTML = formStr;
        document.body.appendChild(div);
        document.forms[document.forms.length - 1].submit();
      }
    } else if (response && response.status === 420) {
      history.push(ROUTE_USER_INFO_REGISTER);
    } else if (response && response.status === 422) {
      if (callback) {
        callback(e.response);
      }
    } else {
      history.push(`${ROUTE_ERROR_PAGE}/systemError`);
    }
  };
}
