import { combineReducers } from 'redux';

import createReservationPreCondState from './utils/reducers/ReservationCreate';
import { CreateReservationPreCond } from './utils/actions/ReservationCreate';
import userInfoReducer, {
  refreshUserInfoFlag,
} from './utils/reducers/UserInfo';
import updateStatusReducer from './utils/reducers/UpdateStatus';
import dataListReducer from './utils/reducers/DataList';
import { DataListState } from './utils/actions/DataList';
import { UserInfoWithOperationAuth as ApiUserInfo } from './api/user';
import reservationDetailFrom from './utils/reducers/ReservationDetail';

export declare type ReservationPreCond = CreateReservationPreCond;
export declare type UserInfo = ApiUserInfo;

export interface RootState {
  reservationPreCond: ReservationPreCond;
  userInfo: UserInfo;
  updateStatus: number;
  dataList: DataListState;
  reservationFromSchedule: string;
  refreshUserInfoFlag: boolean;
}

const rootReducer = combineReducers<RootState>({
  reservationPreCond: createReservationPreCondState,
  userInfo: userInfoReducer,
  updateStatus: updateStatusReducer,
  dataList: dataListReducer,
  reservationFromSchedule: reservationDetailFrom,
  refreshUserInfoFlag: refreshUserInfoFlag,
});

export default rootReducer;
