import { ReduxAction } from '../../reduxHelper';
import * as Action from '../../actions/UpdateStatus';

const defaultState: number = 0;

const updateStatusReducer = (
  state = defaultState,
  action: ReduxAction<number>
): number => {
  switch (action.type) {
    case Action.SET_UPDATE_STATUS:
      return action.payload || defaultState;
    case Action.REMOVE_UPDATE_STATUS:
      return defaultState;
    default:
      return state;
  }
};

export default updateStatusReducer;
