import Messages, { MessagesInterface } from './messages';

export const ROUTE_TOP = '/top';
export const ROUTE_USER_INFO_REGISTER = '/user/register';
export const ROUTE_REGISTRATION = '/registration';
export const ROUTE_ERROR_PAGE = '/error';
export const ROUTE_MANAGE_COMMON = '/management/';
export const ROUTE_MANAGE_MANAGER = '/management/manager';
export const ROUTE_MANAGE_UNIT_LIST = '/management/unit/list';
export const ROUTE_MANAGE_UNIT_DETAIL = '/management/unit/view';
export const ROUTE_MANAGE_UNIT_EDIT = '/management/unit/edit';
export const ROUTE_MANAGE_SYSTEM_ADMIN_LIST = '/management/system-admin-list';
export const ROUTE_MANAGE_PREFECTURE_ADMIN_LIST =
  '/management/prefecture-admin-list';
export const ROUTE_MANAGE_VENUE_LIST = '/management/venue/list';
export const ROUTE_MANAGE_VENUE_EDIT = '/management/venue/edit';
export const ROUTE_MANAGE_VENUE_DETAIL = '/management/venue/view';
export const ROUTE_MANAGE_INFORM_LIST = '/management/inform/list';
export const ROUTE_USER_INFORM_LIST = '/inform/list';
export const ROUTE_MANAGE_INFORM_DETAIL = '/management/inform/view';
export const ROUTE_USER_INFORM_DETAIL = '/inform/view';
export const ROUTE_MANAGE_INFORM_EDIT = '/management/inform/edit';
export const ROUTE_MANAGE_ROOM = '/management/room-list';
export const ROUTE_MANAGE_RESERVATION = '/management/reservation/list';
export const ROUTE_MANAGE_RESERVATION_DETAIL = '/management/reservation/view';
export const ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST =
  '/management/master/participant-type-list';
export const ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST =
  '/management/master/meeting-type-list';
export const ROUTE_RESERVATION_DETAIL = '/reservation/view';
export const ROUTE_RESERVATION_LIST = '/reservation/list';
export const ROUTE_USER_INFO_EDIT = '/user/edit';
export const ROUTE_MANAGE_RESERVATION_DETAIL_EDIT =
  '/management/reservation/edit';
export const ROUTE_MANAGE_RESERVATION_COPY = '/management/reservation/copy';
export const ROUTE_RESERVATION_EDIT = '/reservation/edit';
export const ROUTE_RESERVATION_COPY = '/reservation/copy';
export const ROUTE_VENUE_SCHEDULE = '/schedule/unit/:unit/venue/:venue/:date';
export const ROUTE_VENUE_ROOM_DETAIL = '/management/venue/room/view';
export const ROUTE_VENUE_EQUIP_LIST = '/management/master/equip/list';

export const API_LOGIN = '/api/login';
export const API_LOGOUT = '/saml/logout';
export const API_GET_USERINFO = '/api/userInfo';
export const API_GET_UNITLIST = '/api/unit/list';
export const API_GET_UNIT_DETAIL = '/api/unit/id/{}';
export const API_UNIT_ADD = '/api/unit/add';
export const API_UNIT_UPDATE = '/api/unit/update';
export const API_UNIT_DELETE = '/api/unit/delete';
export const API_GET_USER_UPDATE = '/api/user/update/me';
export const API_PUT_USER_INVALIDATE_COOKIE = '/api/user/invalidateCookie';
export const API_GET_PREFECTURE_LIST = '/api/prefecture/list';
export const API_GET_ORGANIZATION_RELATION_TREE = '/api/organization/tree';
export const API_GET_ORGANIZATION_TREE_DETAIL = '/api/organization/treefrom';
export const API_GET_ORGANIZATION_SEARCH = '/api/organization/list';
export const API_GET_ORGANIZATION_FOR_RESERVATION =
  '/api/organization/forreservation';
export const API_GET_SYSTEM_ADMIN_LIST = '/api/sysadm/list';
export const API_GET_PREFECTURE_ADMIN_LIST = '/api/prefadm/list';
export const API_GET_REGION_ADMIN_LIST = 'api/regionAdminList';
export const API_GET_PARTICIPANT_TYPE_LIST = '/api/participantType/list';
export const API_UPDATE_PARTICIPANT = '/api/participantType/update';
export const API_GET_MEETING_TYPE_LIST = '/api/meetingType/list';
export const API_UPDATE_MEETING_TYPE = '/api/meetingType/update';
export const API_GET_VENUELIST = '/api/venue/list';
export const API_GET_VENUE_INFO = '/api/venue/id';
export const API_GET_VENUE_ADD = '/api/venue/add';
export const API_GET_VENUE_UPDATE = '/api/venue/update';
export const API_GET_VENUE_DELETE = '/api/venue/delete';
export const API_GET_VENUE_ROOM_DETAIL = '/api/room/id/:venueId/:roomId';
export const API_GET_VENUE_ROOM_UPDATE = '/api/room/update';
export const API_DELETE_VENUE_ROOM = '/api/room/delete/:venueId/:roomId';
export const API_GET_INFORMLIST = '/api/inform/list';
export const API_GET_INFORM_DETAIL = '/api/inform/id/{}';
export const API_UPDATE_INFORM = '/api/inform/update';
export const API_PUT_INFORM_ADD = '/api/inform/add';
export const API_DELETE_INFORM_DEL = '/api/inform/delete/{}';
export const API_GET_EQUIP_LIST = '/api/equip/list';
export const API_UPDATE_EQUIP = '/api/equip/update';
export const API_GET_MY_INFO = '/api/user/me';
export const API_GET_USER_INFO = '/api/user/id/{}';
export const API_GET_USER_LIST = '/api/user/list';
export const API_SEARCH_USER_FOR_UNIT = '/api/user/forunit';
export const API_SEARCH_USER_FOR_VENUE = '/api/user/forvenue';
export const API_GET_RESERVE_LIST_BY_QUERY = '/api/reservation/list';
export const API_GET_RESERVATION_INFO = '/api/reservation/id/{}';
export const API_DELETE_RESERVATION = '/api/reservation/delete';
export const API_GET_RESERVATION_FOR_PRESET =
  '/api/reservation/preset/:venueId';
export const API_GET_RESERVATION_MYLIST = '/api/reservation/my';
export const API_ADD_RESERVATION = '/api/reservation/add';
export const API_UPDATE_RESERVATION = '/api/reservation/update';
export const API_GET_USER_AUTHORITIES = '/api/authorities/id/{}';
export const API_UPDATE_USER_AUTHORITIES = '/api/authorities/update';
export const API_MY_INFORM = '/api/inform/my';
export const API_MY_PREFECTURE = '/api/prefecture/my';
export const API_MY_UNIT = '/api/unit/my';
export const API_MY_VENUE = '/api/venue/my';
export const API_VENUE_SCHEDULE = '/api/reservation/ofvenue/list/:venue/:date';
export const API_VENUE_SCHEDULE_FAX = '/api/reservation/fax/:venue/:date';
export const API_VENUE_SCHEDULE_PRINT = '/api/reservation/print/:venue/:date';

export const API_UPDATE_SYSADMIN = '/api/sysadm/update';

export const API_UPDATE_PREFECTURE = '/api/prefadm/update';
export const API_GET_MY_MENU = '/api/menu/my';
export const API_AUTH_SAML = '/saml/login';
export const API_VERSION = '/api/version';

export const MOBILE_SWITCH_WIDTH = 768;

export const PAGE_LIMIT = 20;

export const DATE_YMDW_VIEW_FORMAT = 'YYYY/MM/DD (dddd)';
export const DATE_YMD_VIEW_FORMAT = 'YYYY/MM/DD';
export const DATE_YMD_VALUE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'HH:mm';

export const UPDATE_ACTION_UPDATE = 'put';
export const UPDATE_ACTION_DEL = 'delete';

export const INFORM_TYPE_MSG: MessagesInterface = {
  ALL: Messages['management.inform.list.informType1'],
  PREFECTURE: Messages['management.inform.list.informType2'],
  UNIT: Messages['management.inform.list.informType3'],
};

export const LOGIN_REDIRECT_TO = 'loginRedirectTo';
