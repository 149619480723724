import fetch, { AxiosRequestConfig } from 'axios';

export interface CommonError {
  [prop: string]: any;
}

export interface ResponseBody<T> {
  data?: T;
  error?: CommonError;
}

const DEFAULT_REQ_HEADER = () => {
  return { credentials: 'include' };
};

function requestPromise<T = any>(
  options: AxiosRequestConfig
): Promise<ResponseBody<T>> {
  return new Promise<ResponseBody<T>>((resolve, reject) => {
    fetch(options)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function get<T = any>(url: string): Promise<ResponseBody<T>> {
  const config: AxiosRequestConfig = {
    url: url,
    method: 'get',
    headers: DEFAULT_REQ_HEADER(),
  };
  return requestPromise<T>(config);
}

export function post<T = any>(
  url: string,
  body = {}
): Promise<ResponseBody<T>> {
  const config: AxiosRequestConfig = {
    url: url,
    method: 'post',
    headers: DEFAULT_REQ_HEADER(),
    data: body,
  };
  return requestPromise<T>(config);
}

export function put<T = any>(url: string, body = {}): Promise<ResponseBody<T>> {
  const config: AxiosRequestConfig = {
    url: url,
    method: 'put',
    headers: DEFAULT_REQ_HEADER(),
    data: body,
  };
  return requestPromise<T>(config);
}

export function del<T = any>(url: string, body = {}): Promise<ResponseBody<T>> {
  const config: AxiosRequestConfig = {
    url: url,
    method: 'delete',
    headers: DEFAULT_REQ_HEADER(),
    data: body,
  };
  return requestPromise<T>(config);
}
