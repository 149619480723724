import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { ROUTE_ERROR_PAGE, ROUTE_USER_INFO_REGISTER } from './utils/constants';

import CommonLayout, { CommonLayoutPaths } from './modules/system/CommonLayout';
import ErrorBoundary from './modules/system/components/ErrorBoundary';

import rootReducer from './rootReducer';

import ErrorPage from './modules/system/pages/ErrorPage';
import UserRegister from './modules/system/pages/UserEdit';

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

const NotFound: React.FC = () => {
  return (
    <Redirect
      to={{
        pathname: `${ROUTE_ERROR_PAGE}/404`,
      }}
    />
  );
};

const App: React.FC = () => {
  const commonLayoutPaths = CommonLayoutPaths.map((p) => p.path);
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={`${ROUTE_ERROR_PAGE}/:reason`} component={ErrorPage} />
            <Route
              path={ROUTE_USER_INFO_REGISTER}
              exact
              component={UserRegister}
            />
            <Route path={commonLayoutPaths} exact component={CommonLayout} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
