import React from 'react';

import { ErrorComponent } from '../../pages/ErrorPage';
import { Errors } from '../../pages/ErrorPage/errorMsg';

interface Props {
  children: React.ReactNode;
}

export default class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any) {
    this.setState({ hasError: true });
    console.log(error);
  }

  handleTopBtnClick() {
    window.location.href = '/';
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      return (
        <ErrorComponent
          instance={Errors.systemError}
          handleTopBtnClick={this.handleTopBtnClick}
        />
      );
    }

    return this.props.children;
  }
}
