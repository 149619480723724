import React, { useEffect, useState } from 'react';

import checkIcon from '../../../../assets/images/check-circle.svg';

import styles from './index.module.scss';

interface MessageModalProps {
  message: string;
  show: boolean;
  interval?: number;
  closeCallBack?: () => void;
}

const MessageModal: React.FC<MessageModalProps> = (
  props: MessageModalProps
) => {
  const { message, show, interval } = props;
  const [showMessage, setShowMessage] = useState(show);
  let timer: number;

  useEffect(() => {
    if (show) {
      setShowMessage(true);
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        setShowMessage(false);
        if (props.closeCallBack) {
          props.closeCallBack();
        }
      }, interval);
    }
  }, [show]);

  return (
    <>
      {showMessage && (
        <div className={styles.contentWrapper}>
          <div className={styles.message}>
            <img src={checkIcon} alt="check" className={styles.checkIcon} />
            {message}
          </div>
        </div>
      )}
    </>
  );
};

MessageModal.defaultProps = {
  interval: 3000,
};

export default MessageModal;
