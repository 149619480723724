import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  RouteComponentProps,
} from 'react-router-dom';
import { Drawer, Button, Dropdown, Menu, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useWindowSize, useMount } from 'react-use';

import {
  ROUTE_MANAGE_UNIT_LIST,
  ROUTE_MANAGE_UNIT_DETAIL,
  ROUTE_MANAGE_UNIT_EDIT,
  ROUTE_MANAGE_VENUE_LIST,
  ROUTE_MANAGE_SYSTEM_ADMIN_LIST,
  API_GET_MY_INFO,
  ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST,
  ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST,
  API_GET_MY_MENU,
  API_LOGOUT,
  ROUTE_MANAGE_RESERVATION,
  ROUTE_MANAGE_RESERVATION_DETAIL,
  ROUTE_MANAGE_RESERVATION_DETAIL_EDIT,
  ROUTE_RESERVATION_LIST,
  ROUTE_MANAGE_INFORM_LIST,
  ROUTE_MANAGE_INFORM_DETAIL,
  ROUTE_MANAGE_PREFECTURE_ADMIN_LIST,
  ROUTE_MANAGE_INFORM_EDIT,
  ROUTE_USER_INFORM_LIST,
  ROUTE_USER_INFORM_DETAIL,
  ROUTE_TOP,
  ROUTE_VENUE_SCHEDULE,
  ROUTE_RESERVATION_EDIT,
  ROUTE_RESERVATION_DETAIL,
  ROUTE_RESERVATION_COPY,
  ROUTE_MANAGE_RESERVATION_COPY,
  ROUTE_VENUE_EQUIP_LIST,
  ROUTE_USER_INFO_EDIT,
  ROUTE_MANAGE_VENUE_EDIT,
  ROUTE_MANAGE_VENUE_DETAIL,
  ROUTE_VENUE_ROOM_DETAIL,
} from '../../../utils/constants';
import { get } from '../../../utils/fetch';
import * as Action from '../../../utils/actions/UserInfo';
import { RootState } from '../../../rootReducer';
import { isMobileWhenConstract, isMobileMode } from '../../../utils/helper';
import { UserInfoWithOperationAuth } from '../../../api/user';
import { SideMenuResponse } from '../../../api/menu';
import useFetch from '../../../utils/hooks/useFetch';
import CommonHeader from '../../system/components/CommonHeader';
import SideMenu, { SideMenuProps } from '../../system/components/SideMenu';
import WarningModal from '../components/WarningModal';
import Logo from '../../../assets/images/kaikan_logo.svg';
import VenusBtn from '../components/VenusBtn';
import { ReactComponent as HamburgerBar } from '../../../assets/images/bars-solid.svg';
import { ReactComponent as UserAvatar } from '../../../assets/images/user.svg';
import Messages from '../../../utils/messages';

import styles from './index.module.scss';

const UnitList = React.lazy(() => import('../../management/pages/UnitList'));
const UnitDetail = React.lazy(
  () => import('../../management/pages/UnitDetail')
);
const UnitEdit = React.lazy(() => import('../../management/pages/UnitEdit'));
const SystemAdminList = React.lazy(
  () => import('../../management/pages/SystemAdminList')
);
const PrefectureAdminList = React.lazy(
  () => import('../../management/pages/PrefectureAdminList')
);
const ReserveStatusDetail = React.lazy(
  () => import('../pages/ReservationDetail')
);
const VenueList = React.lazy(() => import('../../management/pages/VenueList'));
const InformList = React.lazy(
  () => import('../../management/pages/InformList')
);
const InformListManage = React.lazy(
  () => import('../../management/pages/InformList')
);
const ReservationList = React.lazy(
  () => import('../../management/pages/ReservationList')
);
const ReservationEdit = React.lazy(
  () => import('../../system/pages/ReservationEdit')
);
const ReservationCopy = React.lazy(
  () => import('../../system/pages/ReservationEdit')
);
const SelfReserveList = React.lazy(
  () => import('../../reserve/pages/MyReservationList')
);
const InformDetail = React.lazy(
  () => import('../../management/pages/InformDetail')
);
const InformDetailManage = React.lazy(
  () => import('../../management/pages/InformDetail')
);
const InformEditor = React.lazy(
  () => import('../../management/pages/InformEditor')
);

const Top = React.lazy(() => import('../../reserve/pages/Top'));

const VenueSchdule = React.lazy(
  () => import('../../reserve/pages/VenueSchedule')
);

const VenueEquipList = React.lazy(
  () => import('../../management/pages/VenueEquipList')
);
const UserEdit = React.lazy(() => import('../pages/UserEdit'));

const VenueEdit = React.lazy(() => import('../../management/pages/VenueEdit'));
const VenueDetail = React.lazy(
  () => import('../../management/pages/VenueDetail')
);
const RoomDetail = React.lazy(
  () => import('../../management/pages/RoomDetail')
);
const ReservParticipantTypeList = React.lazy(
  () => import('../../management/pages/MeetingAndParticipantTypeList')
);

const ReserverMeetingTypeList = React.lazy(
  () => import('../../management/pages/MeetingAndParticipantTypeList')
);

export const CommonLayoutPaths: {
  path: string;
  exact: boolean;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}[] = [
  {
    path: '/',
    exact: true,
    component: Top,
  },
  {
    path: ROUTE_TOP,
    exact: true,
    component: Top,
  },
  {
    path: ROUTE_MANAGE_UNIT_LIST,
    exact: true,
    component: UnitList,
  },
  {
    path: `${ROUTE_MANAGE_UNIT_DETAIL}/:id`,
    exact: true,
    component: UnitDetail,
  },
  {
    path: `${ROUTE_MANAGE_UNIT_EDIT}/:id`,
    exact: true,
    component: UnitEdit,
  },
  {
    path: ROUTE_MANAGE_UNIT_EDIT, // idがなしの場合、ユニット新規画面として描画します
    exact: true,
    component: UnitEdit,
  },
  {
    path: ROUTE_MANAGE_SYSTEM_ADMIN_LIST,
    exact: true,
    component: SystemAdminList,
  },
  {
    path: ROUTE_MANAGE_PREFECTURE_ADMIN_LIST,
    exact: true,
    component: PrefectureAdminList,
  },
  {
    path: ROUTE_MANAGE_VENUE_LIST,
    exact: true,
    component: VenueList,
  },
  {
    path: `${ROUTE_MANAGE_RESERVATION}`,
    exact: true,
    component: ReservationList,
  },
  {
    path: ROUTE_MANAGE_VENUE_EDIT,
    exact: true,
    component: VenueEdit,
  },
  {
    path: `${ROUTE_MANAGE_VENUE_DETAIL}/:id`,
    exact: true,
    component: VenueDetail,
  },
  {
    path: `${ROUTE_MANAGE_VENUE_EDIT}/:id`,
    exact: true,
    component: VenueEdit,
  },
  {
    path: `${ROUTE_MANAGE_RESERVATION_DETAIL}/:id`,
    exact: true,
    component: ReserveStatusDetail,
  },
  {
    path: `${ROUTE_MANAGE_RESERVATION_DETAIL_EDIT}/:id`,
    exact: true,
    component: ReservationEdit,
  },
  {
    path: `${ROUTE_MANAGE_RESERVATION_COPY}/:id`,
    exact: true,
    component: ReservationCopy,
  },
  {
    path: ROUTE_RESERVATION_LIST,
    exact: true,
    component: SelfReserveList,
  },
  {
    path: ROUTE_MANAGE_INFORM_LIST,
    exact: true,
    component: InformListManage,
  },
  {
    path: ROUTE_USER_INFORM_LIST,
    exact: true,
    component: InformList,
  },
  {
    path: `${ROUTE_MANAGE_INFORM_DETAIL}/:id`,
    exact: true,
    component: InformDetailManage,
  },
  {
    path: `${ROUTE_USER_INFORM_DETAIL}/:id`,
    exact: true,
    component: InformDetail,
  },
  {
    path: `${ROUTE_MANAGE_INFORM_EDIT}/:id`,
    exact: true,
    component: InformEditor,
  },
  {
    path: `${ROUTE_MANAGE_INFORM_EDIT}`,
    exact: true,
    component: InformEditor,
  },
  {
    path: `${ROUTE_MANAGE_MASTER_PARTICIPANTTYPE_LIST}`,
    exact: true,
    component: ReservParticipantTypeList,
  },
  {
    path: `${ROUTE_MANAGE_MASTER_MEETINGTYPE_LIST}`,
    exact: true,
    component: ReserverMeetingTypeList,
  },
  {
    path: `${ROUTE_VENUE_SCHEDULE}`,
    exact: true,
    component: VenueSchdule,
  },
  {
    path: `${ROUTE_RESERVATION_EDIT}`,
    exact: true,
    component: ReservationEdit,
  },
  {
    path: `${ROUTE_RESERVATION_EDIT}/:id`,
    exact: true,
    component: ReservationEdit,
  },
  {
    path: `${ROUTE_RESERVATION_COPY}/:id`,
    exact: true,
    component: ReservationCopy,
  },
  {
    path: `${ROUTE_RESERVATION_DETAIL}/:id`,
    exact: true,
    component: ReserveStatusDetail,
  },
  {
    path: `${ROUTE_VENUE_EQUIP_LIST}`,
    exact: true,
    component: VenueEquipList,
  },
  {
    path: ROUTE_USER_INFO_EDIT,
    exact: true,
    component: UserEdit,
  },
  {
    path: `${ROUTE_VENUE_ROOM_DETAIL}/:venueId/:roomId`,
    exact: true,
    component: RoomDetail,
  },
];

export const LayoutContext = React.createContext('openSideMenu');

const CommonLayout: React.FC = () => {
  const { width } = useWindowSize();
  const [collapsedSideMenu, setCollapsedSideMenu] = useState(
    isMobileWhenConstract()
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [userInfoState, fetchUserInfo] = useFetch<UserInfoWithOperationAuth>(
    get
  );
  const [myMenu, fetchMyMenu] = useFetch<SideMenuResponse>(get);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const refreshUserInfoAndSideMenuFlag = useSelector<RootState, boolean>(
    (state) => state.refreshUserInfoFlag
  );

  useMount(() => {
    fetchUserInfo({ url: API_GET_MY_INFO });
    fetchMyMenu({ url: API_GET_MY_MENU });
  });

  useEffect(() => {
    if (refreshUserInfoAndSideMenuFlag) {
      fetchUserInfo({ url: API_GET_MY_INFO });
      fetchMyMenu({ url: API_GET_MY_MENU });
      dispatch(Action.removeRefreshUserInfoFlag());
    }
  }, [refreshUserInfoAndSideMenuFlag]);

  useEffect(() => {
    if (
      userInfoState &&
      !userInfoState.isFetching &&
      userInfoState.data &&
      userInfoState.data.commonId
    ) {
      dispatch(Action.setUserInfo(userInfoState.data));
    }
  }, [userInfoState]);

  useEffect(() => {
    if (
      [
        ROUTE_MANAGE_UNIT_LIST,
        ROUTE_MANAGE_VENUE_LIST,
        ROUTE_USER_INFORM_LIST,
        ROUTE_MANAGE_INFORM_LIST,
        ROUTE_RESERVATION_LIST,
      ].indexOf(history.location.pathname) === -1
    ) {
      window.scrollTo(0, 0);
    }
  }, [history.location.pathname]);

  const handelLogoClick = () => {
    history.push('/');
    if (!collapsedSideMenu) {
      setCollapsedSideMenu(true);
    }
  };

  const logoEl = (
    <span className={styles.logoContainer}>
      <img src={Logo} alt="logo" onClick={handelLogoClick} />
    </span>
  );

  const handleSideMenuTroggle = (): void => {
    setCollapsedSideMenu((preState) => {
      return !preState;
    });
  };

  const sideMenuBtn = (
    <span className={styles.hamburgerbarContainer}>
      <Button onClick={handleSideMenuTroggle}>
        <HamburgerBar />
      </Button>
    </span>
  );

  const getUserName = () => {
    if (!userInfoState.data || !userInfoState.data.commonId) {
      return '';
    }
    return `${userInfoState.data.familyName} ${userInfoState.data.givenName}`;
  };

  const handleEditUserInfoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(ROUTE_USER_INFO_EDIT);
  };

  const doLogout = async () => {
    const fetchLogout = async () => {
      const result = await get(API_LOGOUT);
      return result;
    };
    try {
      const result = await fetchLogout();
    } catch (e) {
      const { response } = e;
      if (response && response.status === 401) {
        const contentType = response.headers['content-type'];
        if (contentType.indexOf('text/html') !== -1) {
          const formStr = response.data as string;
          const div = document.createElement('div');
          div.innerHTML = formStr;
          document.body.appendChild(div);
          document.forms[document.forms.length - 1].submit();
        }
      }
    }
  };

  const handleLogoutClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setShowLogoutModal(true);
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={handleEditUserInfoClick}>
          {Messages['user.edit.page.name']}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={handleLogoutClick}>
          {Messages['management.commom.operator.logout']}
        </a>
      </Menu.Item>
    </Menu>
  );

  const AVATATNAME_LENGTH_LIMIT = 12;
  const getAvatorPcWrapperClassName = (userName: string): string => {
    if (userName.length > AVATATNAME_LENGTH_LIMIT) {
      return `${styles.userAvatarPcWrapper} ${styles.userAvatarPcWrapperLine2}`;
    }
    return styles.userAvatarPcWrapper;
  };

  const userAvatar = (
    <Dropdown
      overlay={menu}
      overlayClassName={styles.userInfoDropDownWrapper}
      placement="bottomRight"
      trigger={['click']}
    >
      <span
        className={
          isMobileMode(width)
            ? styles.userAvatarMobileWrapper
            : getAvatorPcWrapperClassName(getUserName())
        }
      >
        {isMobileMode(width) && (
          <Button>
            <UserAvatar />
          </Button>
        )}
        {!isMobileMode(width) && (
          <VenusBtn
            type="text"
            height={
              getUserName().length > AVATATNAME_LENGTH_LIMIT ? 50 : undefined
            }
          >
            <span className={styles.userNameWrapper}>
              <span className={styles.userName}>{getUserName()}</span>
            </span>
          </VenusBtn>
        )}
      </span>
    </Dropdown>
  );

  const layoutContextValue = collapsedSideMenu
    ? 'collapsedSideMenu'
    : 'openSideMenu';

  const antIcon = (
    <LoadingOutlined
      style={{
        position: 'absolute',
        fontSize: 56,
        top: '50%',
        left: '49%',
        color: '#0085af',
      }}
      spin
    />
  );

  const loading = (
    <div className={styles.loadingWrapper}>
      <Spin indicator={antIcon} />
    </div>
  );

  if (userInfoState.isFetching || myMenu.isFetching) {
    return loading;
  }

  const generateSideMenuProps = (): SideMenuProps | undefined => {
    if (!myMenu.data || !myMenu.data.items) {
      return undefined;
    }

    const mySideMenu = myMenu.data.items;
    if (JSON.stringify(mySideMenu).indexOf('true') === -1) {
      return undefined;
    }
    const sideMenuProps = {
      ...mySideMenu,
      collapsed: collapsedSideMenu,
      history: history,
      onClickCallback: isMobileMode(width)
        ? () => {
            setCollapsedSideMenu(true);
          }
        : undefined,
    };
    return sideMenuProps;
  };

  const sideMenuProps = generateSideMenuProps();

  return (
    <>
      <CommonHeader
        title={logoEl}
        rightOperation={userAvatar}
        leftOperation={sideMenuProps ? sideMenuBtn : undefined}
        zIndex={isMobileMode(width) && !collapsedSideMenu ? 1001 : 1000}
      />
      <div className={styles.mainWrapper}>
        {!isMobileMode(width) && sideMenuProps && (
          <div className={styles.fixedSideMenuWrapper}>
            <SideMenu {...sideMenuProps} />
          </div>
        )}
        {isMobileMode(width) && sideMenuProps && (
          <Drawer
            placement="left"
            closable
            maskClosable
            onClose={() => {
              setCollapsedSideMenu(true);
            }}
            visible={!collapsedSideMenu}
            bodyStyle={{
              padding: '50px 0 0 0',
            }}
          >
            <SideMenu {...sideMenuProps} />
          </Drawer>
        )}
        <div
          className={
            !isMobileMode(width) && !collapsedSideMenu && sideMenuProps
              ? `${styles.commonLayoutWrapper} ${styles.sideMenuOpenPadding}`
              : styles.commonLayoutWrapper
          }
        >
          <LayoutContext.Provider value={layoutContextValue}>
            <Suspense fallback={loading}>
              <Switch>
                {CommonLayoutPaths.map((p) => {
                  return (
                    <Route
                      path={p.path}
                      exact={p.exact}
                      component={p.component}
                    />
                  );
                })}
              </Switch>
            </Suspense>
          </LayoutContext.Provider>
        </div>
      </div>
      <WarningModal
        setModalVisible={setShowLogoutModal}
        confirmSetting={doLogout}
        visible={showLogoutModal}
        id=""
        hideWarning
        message={Messages['management.commom.message.logout']}
        operationName={Messages['management.commom.operator.logout']}
        modalWrapperClassName={styles.logoutModalWrapper}
        modalBodyClassName={styles.logoutModalBody}
        withoutMinHeight
        animationName="EXIT"
      />
    </>
  );
};

export default CommonLayout;
