import React, { useState } from 'react';
import { useMount } from 'react-use';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, useParams } from 'react-router-dom';

import * as DataListActions from '../../../../utils/actions/DataList';
import * as UpdateActions from '../../../../utils/actions/UpdateStatus';
import VenusBtn from '../../components/VenusBtn';
import LottieComponent from '../../components/LottieComponent';
import errorAnimation from '../../../../assets/images/extramation.json';
import unAvailableLogo from '../../../../assets/images/kaikan_503.svg';
import Loading from '../../../system/components/Loading';
import {
  API_GET_MY_INFO,
  ROUTE_TOP,
  API_PUT_USER_INVALIDATE_COOKIE,
  UPDATE_ACTION_UPDATE,
} from '../../../../utils/constants';
import { get, put } from '../../../../utils/fetch';
import useCatchCommonFetchError from '../../../../utils/hooks/useCatchCommonFetchError';

import useFetchData from '../../../../utils/hooks/useFetch';
import Messages from '../../../../utils/messages';

import getErrorMsg, {
  ReturnToTopMsg,
  ErrorInstance,
  ServiceUnavailable,
  UserInfoNotEnough,
} from './errorMsg';
import styles from './index.module.scss';

interface ErrorComponentProps {
  instance: ErrorInstance;
  handleTopBtnClick: () => void;
}

export const ErrorComponent: React.FC<ErrorComponentProps> = (
  props: ErrorComponentProps
) => {
  const { instance, handleTopBtnClick } = props;
  return (
    <div className={styles.errorPageWrapper}>
      <div className={styles.errorPageContent}>
        <LottieComponent
          animationData={errorAnimation}
          className={styles.errorAni}
        />
        <div className={styles.title}>{instance.title}</div>
        <div className={styles.detail}>{instance.detail}</div>
        {instance.returnToTop && (
          <div className={styles.returnToTopWrapper}>
            <p>{ReturnToTopMsg.hint}</p>
            <div className={styles.btnWrapper}>
              <VenusBtn type="gradient" onClick={handleTopBtnClick}>
                {ReturnToTopMsg.btn}
              </VenusBtn>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ErrorPage: React.FC<RouteComponentProps<{}>> = (
  props: RouteComponentProps<{}>
) => {
  const { reason } = useParams<{ reason: string }>();
  const dispatch = useDispatch();
  const instance = getErrorMsg(reason);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [invalidateCookieResult, invalidateCookie] = useFetchData<string>(
    put,
    ''
  );
  const [unavailableError, setUnavailableError] = useState<
    ServiceUnavailable | undefined
  >();
  const [userInfoNotEnoughError, setUserInfoNotEnoughError] = useState<
    UserInfoNotEnough | undefined
  >();

  const handleCommonFetchError = useCatchCommonFetchError();

  useMount(() => {
    // エラーが起こった際に、Store内のStateをResetする
    dispatch(DataListActions.resetDataList());
    dispatch(UpdateActions.removeUpdateStatus());
    const fetchUserInfo = async () => {
      try {
        setFetching(true);
        await get(API_GET_MY_INFO);
        setFetching(false);
        // APIが動ける際に、Top画面へ遷移する
        props.history.push(ROUTE_TOP);
      } catch (e) {
        setFetching(false);
        const { response } = e;
        if (response && response.status === 503) {
          if (response.data) {
            const error = response.data as ServiceUnavailable;
            setUnavailableError(error);
          }
        } else if (response && response.status === 428) {
          if (response.data) {
            const error = response.data as UserInfoNotEnough;
            setUserInfoNotEnoughError(error);
          }
        } else {
          handleCommonFetchError(e);
        }
      }
    };
    if (reason === '503' || reason === '428') {
      fetchUserInfo();
    }
  });

  const handleTopBtnClick = () => {
    props.history.push('/');
  };

  const clearCookie = () => {
    invalidateCookie({
      url: API_PUT_USER_INVALIDATE_COOKIE,
      data: null,
      method: UPDATE_ACTION_UPDATE,
    });
  };

  if (isFetching) {
    return <Loading />;
  }

  if (unavailableError) {
    return (
      <div className={styles.errorPageWrapper}>
        <div className={styles.errorPageContent}>
          <img src={unAvailableLogo} alt="logo" className={styles.errorAni} />
          <div className={styles.title}>{unavailableError.message}</div>
          <div className={styles.description}>
            {unavailableError.description1}
          </div>
          <div className={styles.description}>
            {unavailableError.description2}
          </div>
        </div>
      </div>
    );
  }

  if (userInfoNotEnoughError) {
    return (
      <div className={styles.errorPageWrapper}>
        <div className={styles.errorPageContent}>
          <LottieComponent
            animationData={errorAnimation}
            className={styles.errorAni}
          />
          <div className={styles.title}>{userInfoNotEnoughError.message}</div>
          {userInfoNotEnoughError.description && (
            <div className={styles.description}>
              {userInfoNotEnoughError.description}
            </div>
          )}
          {userInfoNotEnoughError.executiveCodeUrl && (
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.userLink}
              onClick={clearCookie}
              href={userInfoNotEnoughError.executiveCodeUrl}
            >
              {Messages['error.user.info.not.enough.link.executive.code']}
            </a>
          )}
        </div>
      </div>
    );
  }
  return (
    <ErrorComponent instance={instance} handleTopBtnClick={handleTopBtnClick} />
  );
};

export default ErrorPage;
