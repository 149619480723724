import { ReduxAction } from '../../reduxHelper';
import { Equipment } from '../../../api/room';

export const SET_RESERVATION_PRE_COND = 'SET_RESERVATION_PRE_COND';
export const REMOVE_RESERVATION_PRE_COND = 'REMOVE_RESERVATION_PRE_COND';

export interface CreateReservationPreCond {
  unitId?: string;
  venueId?: string;
  venueName?: string;
  roomId?: string;
  roomName?: string;
  reservationDate?: string;
  startTime?: string;
  capacity?: number | null;
  equips?: Equipment[];
}

export function setPreCond(
  preCond: CreateReservationPreCond
): ReduxAction<CreateReservationPreCond> {
  return {
    type: SET_RESERVATION_PRE_COND,
    payload: preCond,
  };
}

export function removePreCond() {
  return {
    type: REMOVE_RESERVATION_PRE_COND,
  };
}
