import React from 'react';
import { Input } from 'antd';

import styles from './index.module.scss';

interface VenusFormInputProps {
  className?: string;
  placeholder?: string;
  id?: string;
  value?: any;
  defaultValue?: string;
  error?: boolean;
  maxLength?: number;
  onChange?: (currentValue: string) => void;
  onBlur?: (currentValue: string) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const VenusFormInput: React.FC<VenusFormInputProps> = (
  props: VenusFormInputProps
) => {
  const {
    className,
    placeholder,
    id,
    value,
    error,
    maxLength,
    onChange,
    defaultValue,
    onBlur,
  } = props;

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onBlur) {
      onBlur(e.currentTarget.value);
    }
  };

  let wrapperClassName = error
    ? `${styles.inputWrapper} ${styles.errorState}`
    : `${styles.inputWrapper}`;

  wrapperClassName = className
    ? `${wrapperClassName} ${className}`
    : wrapperClassName;

  return (
    <span className={wrapperClassName} style={props.style}>
      <Input
        placeholder={placeholder}
        id={id}
        defaultValue={defaultValue}
        value={value}
        maxLength={maxLength}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        disabled={props.disabled}
      />
    </span>
  );
};

VenusFormInput.defaultProps = {
  className: undefined,
  placeholder: undefined,
  id: undefined,
  value: undefined,
  error: false,
  maxLength: undefined,
  onChange: undefined,
};

export default VenusFormInput;
