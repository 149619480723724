import React from 'react';

import styles from './index.module.scss';

export interface LabelProps {
  value: string;
  type?: 'primary' | 'mustInput';
  className?: string;
  style?: React.CSSProperties;
}

const Label: React.FC<LabelProps> = (props: LabelProps) => {
  const type = props.type ? props.type : 'primary';
  let className = styles.label;
  if (type === 'mustInput') {
    className = `${className} ${styles.mustInput}`;
  } else {
    className = `${className} ${styles.primary}`;
  }

  if (props.className) {
    className = `${className} ${props.className}`;
  }

  let minWidth;
  if (type === 'primary') {
    minWidth = '3.5rem';
    if (props.value.length > 3) {
      minWidth = `${props.value.length * 0.875 + 0.5}rem`;
    }
  }

  const style = minWidth ? { ...{ minWidth }, ...props.style } : props.style;

  return (
    <span className={className} style={style}>
      {props.value}
    </span>
  );
};

export default Label;
