export interface Page {
  limit: number;
  offset: number;
  totalCount: number;
}

export const generateDefaultPage = (): Page => {
  return {
    limit: 20,
    offset: 0,
    totalCount: 0,
  };
};

export interface Duration {
  from?: string | null;
  to?: string | null;
}

interface InputCheckResult {
  field: string;
  code: string;
  description: string[];
  level: 'INFO' | 'WARN' | 'ERROR';
}

export interface ServerInputCheck {
  message?: string | null;
  errors?: InputCheckResult[] | null;
}
