import React from 'react';
import { useWindowSize } from 'react-use';

import { Select } from 'antd';

import { isMobileMode } from '../../../../utils/helper';

import styles from './index.module.scss';

interface VenusOptionProps {
  value: string | number;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

export const Option: React.FC<VenusOptionProps> = (props: VenusOptionProps) => {
  const { width } = useWindowSize();
  if (isMobileMode(width)) {
    return (
      <option
        value={props.value}
        disabled={props.disabled}
        className={props.className}
      >
        {props.children}
      </option>
    );
  }
  return (
    <Select.Option
      value={props.value}
      disabled={props.disabled}
      className={props.className}
    >
      {props.children}
    </Select.Option>
  );
};

interface VenusFormSelectProps {
  dropdownClassName?: string;
  wrapperClassName?: string;
  error?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
  style?: React.CSSProperties;
  value?: string;
  children?: React.ReactNode;
  placeholder?: string;
}

const VenusFormSelect: React.FC<VenusFormSelectProps> = (
  props: VenusFormSelectProps
) => {
  const { width } = useWindowSize();

  const {
    dropdownClassName,
    wrapperClassName,
    error,
    value,
    disabled,
    children,
    style,
    placeholder,
  } = props;

  const selectStyle: React.CSSProperties = style || {};

  if (isMobileMode(width)) {
    const handleSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      event.preventDefault();
      if (props.onChange) {
        props.onChange(event.target.value || '');
      }
    };

    const getMobileWrapperClassName = () => {
      let className = styles.mobileSelectedValue;
      if (wrapperClassName) {
        className = `${styles.mobileSelectedValue} ${wrapperClassName}`;
      }
      if (error) {
        className = `${className} ${styles.mobileErrorStatus}`;
      }
      return className;
    };

    return (
      <select
        className={getMobileWrapperClassName()}
        style={selectStyle}
        onChange={handleSelectChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      >
        {children}
      </select>
    );
  }

  const dropdownClassNameForProps = dropdownClassName
    ? `${dropdownClassName} ${styles.venusSelectDropdown}`
    : styles.venusSelectDropdown;

  const getPCWrapperClassName = () => {
    let className = styles.venusSelectWrapper;
    if (wrapperClassName) {
      className = `${styles.venusSelectWrapper} ${wrapperClassName}`;
    }
    if (error) {
      className = `${className} ${styles.pcErrorStatus}`;
    }
    return className;
  };
  return (
    <span className={getPCWrapperClassName()}>
      <Select
        value={value}
        disabled={disabled}
        onChange={props.onChange}
        dropdownClassName={dropdownClassNameForProps}
        style={selectStyle}
        placeholder={placeholder}
      >
        {props.children}
      </Select>
    </span>
  );
};
export default VenusFormSelect;
