import { Page } from '../../../api/common/index';

export const SEARCH_DATA_SUCCESS = 'SEARCH_DATA_SUCCESS';
export const SET_SEARCH_CONDITION = 'SET_SEARCH_CONDITION';
export const SET_DO_SEARCH_FLAG = 'SET_DO_SEARCH_FLAG';
export const SET_SCROLL_POS = 'SET_SCROLL_POS';
export const RESET_SEARCH_DATA_RESULTS = 'RESET_SEARCH_DATA_RESULTS';
export const SET_SEARCH_CONDITION_BASE = 'SET_SEARCH_CONDITION_BASE';
export const SET_DATALIST_NAME = 'SET_DATALIST_NAME';

export interface DataListState {
  pageName: string;
  toSearch: boolean;
  result: any[] | null;
  scrollPos: number;
  condition: any;
  conditionBase: any;
  page: Page;
}

export function resetDataList() {
  return {
    type: RESET_SEARCH_DATA_RESULTS,
  };
}
export function setScrollPos(scrollPos: number) {
  return {
    type: SET_SCROLL_POS,
    scrollPos,
  };
}

export function setDoSearchDataFlag(toSearch: boolean) {
  return {
    type: SET_DO_SEARCH_FLAG,
    toSearch,
  };
}

export function setSearchCondition(condition: any) {
  return {
    type: SET_SEARCH_CONDITION,
    condition,
  };
}

export function setSearchConditionBase(conditionBase: any) {
  return {
    type: SET_SEARCH_CONDITION_BASE,
    conditionBase,
  };
}

export function searchDataSuccess(
  result: any,
  page: any = { limit: 20, offset: 0 }
) {
  return {
    type: SEARCH_DATA_SUCCESS,
    result,
    page,
  };
}

export function setDataListName(name: string) {
  return {
    type: SET_DATALIST_NAME,
    name,
  };
}
