import { Page, generateDefaultPage } from '../common';

export interface UserInfoBasic {
  commonId: string;
  familyName: string;
  givenName: string;
  tel: string;
  orgInChargeCode?: string;
  mainPositionCode?: string;
}

export interface UserInfo extends UserInfoBasic {
  emailAddress: string;
  staffCode?: string;
  executiveCode?: string;
  mainPositionName?: string;
  orgInChargeName?: string;
  orgsBelongTo?: Organization[];
  isAdmin: boolean;
  managedPref?: string[];
  managedUnit?: string[];
  managedVenue?: string[];
  rsvConfirmeVenue?: string[];
  isDeleted: boolean;
}

export interface UserInfoWithOperationAuth extends UserInfo {
  isAllowedTo: {
    createUnit: boolean;
    renameUnit: boolean;
    editAuthorities: boolean;
    deleteUnit: boolean;
    editUnitOrg: boolean;
    editUnitVenue: boolean;
    createVenue: boolean;
    deleteVenue: boolean;
    renameVenue: boolean;
    createRoom: boolean;
    editRoom: boolean;
    deleteRoom: boolean;
    editReservation: boolean;
    editInform: boolean;
    manageUnderPref: boolean;
    manageUnderUnit: boolean;
    reserveUnderPref: boolean;
    reserveUnderUnit: boolean;
    selectReservationOrgUnderPref: boolean;
    selectVenueOfUnitOverPref: boolean;
    selectableInformTypes: string[];
    reserveLongTime: boolean;
  };
  _etag: string;
}

export interface Organization {
  orgId: string;
  orgName: string;
  positions: Position[];
}

export interface Position {
  positionId: string;
  positionName: string;
}

export interface Users {
  users: UserInfo[];
  page: Page;
}

export const generateDefaultUserInfo = (): UserInfoWithOperationAuth => {
  return {
    commonId: '',
    emailAddress: '',
    familyName: '',
    givenName: '',
    tel: '',
    executiveCode: '',
    mainPositionCode: '',
    mainPositionName: '',
    orgInChargeCode: '',
    orgInChargeName: '',
    isAdmin: false,
    isAllowedTo: {
      createUnit: false,
      renameUnit: false,
      editAuthorities: false,
      deleteUnit: false,
      editUnitOrg: false,
      editUnitVenue: false,
      createVenue: false,
      deleteVenue: false,
      renameVenue: false,
      createRoom: false,
      editRoom: false,
      deleteRoom: false,
      editReservation: false,
      editInform: false,
      manageUnderPref: false,
      manageUnderUnit: false,
      reserveUnderPref: false,
      reserveUnderUnit: false,
      selectReservationOrgUnderPref: false,
      selectVenueOfUnitOverPref: false,
      selectableInformTypes: [],
      reserveLongTime: false,
    },
    isDeleted: false,
    _etag: '',
  };
};

export const generateDefaultUserList = (): Users => {
  return {
    users: [],
    page: generateDefaultPage(),
  };
};

export interface UserForUnitOrVenue {
  commonId?: string | null;
  name?: string | null;
  selectable: true;
  reason?: string | null;
  reasonDetail?: string | null;
}
