import { ReduxAction } from '../../reduxHelper';
import * as Action from '../../actions/ReservationCreate';

const defaultState: Action.CreateReservationPreCond = {};

const createReservationPreCond = (
  state = defaultState,
  action: ReduxAction<Action.CreateReservationPreCond>
): Action.CreateReservationPreCond => {
  switch (action.type) {
    case Action.SET_RESERVATION_PRE_COND:
      return action.payload || defaultState;
    case Action.REMOVE_RESERVATION_PRE_COND:
      return defaultState;
    default:
      return state;
  }
};

export default createReservationPreCond;
