import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import reportWebVitals from './reportWebVitals';
import { ErrorComponent } from './modules/system/pages/ErrorPage';
import Messages from './utils/messages';
import './assets/globalstyle.scss';

ReactDOM.render(
  <React.StrictMode>
    {navigator.cookieEnabled && <App />}
    {!navigator.cookieEnabled && (
      <ErrorComponent
        instance={{
          title: Messages['hint.allow.cookie'],
          detail: '',
          returnToTop: false,
        }}
        handleTopBtnClick={() => {}}
      />
    )}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
