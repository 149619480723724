import { ReduxAction } from '../../reduxHelper';

export const SET_UPDATE_STATUS = 'SET_UPDATE_STATUS';
export const REMOVE_UPDATE_STATUS = 'REMOVE_UPDATE_STATUS';
export const UPDATE_STATUS_SUCCESS = 1;
export const DEL_STATUS_SUCCESS = 2;
export const UPDATE_STATUS_FAIL = -1;
export const DEL_STATUS_FAIL = -2;

export function setUpdateSuccess(): ReduxAction<number> {
  return {
    type: SET_UPDATE_STATUS,
    payload: UPDATE_STATUS_SUCCESS,
  };
}

export function setUpdateFail(): ReduxAction<number> {
  return {
    type: SET_UPDATE_STATUS,
    payload: UPDATE_STATUS_FAIL,
  };
}

export function setDeleteSuccess(): ReduxAction<number> {
  return {
    type: SET_UPDATE_STATUS,
    payload: DEL_STATUS_SUCCESS,
  };
}

export function setDeleteFail(): ReduxAction<number> {
  return {
    type: SET_UPDATE_STATUS,
    payload: DEL_STATUS_FAIL,
  };
}

export function removeUpdateStatus() {
  return {
    type: REMOVE_UPDATE_STATUS,
  };
}
