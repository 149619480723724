import React from 'react';

import styles from './index.module.scss';

interface VenusBtnProps {
  type?:
    | 'default'
    | 'primary'
    | 'gradient'
    | 'link'
    | 'text'
    | 'danger'
    | 'remind';
  onClick?: (event: React.MouseEvent) => void;
  className?: string;
  children?: React.ReactNode;
  width?: number | string;
  height?: number | string;
  btnId?: string;
  block?: boolean;
  disable?: boolean;
}
const VenusBtn: React.FC<VenusBtnProps> = (props: VenusBtnProps) => {
  let btnClassname = styles.venusBtn;
  const type = props.type || 'default';
  switch (type) {
    case 'primary':
      btnClassname = `${btnClassname} ${styles.venusBtnPrimary}`;
      break;
    case 'danger':
      btnClassname = `${btnClassname} ${styles.venusBtnDanger}`;
      break;
    case 'gradient':
      btnClassname = `${btnClassname} ${styles.venusBtnGradient}`;
      break;
    case 'remind':
      btnClassname = `${btnClassname} ${styles.venusBtnGradientRed}`;
      break;
    case 'link':
      btnClassname = `${btnClassname} ${styles.venusBtnLink}`;
      break;
    case 'text':
      btnClassname = `${btnClassname} ${styles.venusBtnText}`;
      break;
    default:
  }

  if (props.block) {
    btnClassname = `${btnClassname} ${styles.venustBtnBlock}`;
  }

  if (props.className) {
    btnClassname = `${btnClassname} ${props.className}`;
  }

  const style: any = {};
  if (props.width) {
    style.width = props.width;
  }

  if (props.height) {
    style.height = props.height;
  }
  return (
    <button
      className={btnClassname}
      onClick={props.onClick}
      style={style}
      id={props.btnId}
      disabled={props.disable}
    >
      {props.children}
    </button>
  );
};

export default VenusBtn;
