import React from 'react';

import { Modal } from 'antd';

import ModalCloseIcon from '../../../../assets/images/modal-close.svg';

import styles from './index.module.scss';

const VenusModal: React.FC<any> = (props: any) => {
  const {
    wrapClassName,
    fullScreenModal,
    style,
    zIndex,
    width,
    ...rest
  } = props;
  const isFullScreenModal = fullScreenModal || false;
  let zIndexForModal = zIndex || 1000;
  const modalStyle = { ...style };
  let modalWidth = width || 520;
  if (isFullScreenModal) {
    zIndexForModal = 3000;
    modalStyle.position = 'fixed';
    modalStyle.top = 0;
    modalStyle.left = 0;
    modalStyle.right = 0;
    modalStyle.bottom = 0;
    modalStyle.margin = 'unset';
    modalStyle.overflow = 'hidden';
    modalWidth = '100%';
  }

  let venusWrapClassName = wrapClassName
    ? `${styles.venusModalWrapper} ${wrapClassName}`
    : styles.venusModalWrapper;
  if (isFullScreenModal) {
    venusWrapClassName = `${venusWrapClassName} ${styles.venusFullScreenModalWrapper}`;
  }

  const closeIcon = <img src={ModalCloseIcon} alt="close" />;
  return (
    <Modal
      {...rest}
      wrapClassName={venusWrapClassName}
      zIndex={zIndexForModal}
      style={modalStyle}
      width={modalWidth}
      closeIcon={closeIcon}
      centered
    >
      {props.children}
    </Modal>
  );
};

export default VenusModal;
