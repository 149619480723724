import { ReduxAction } from '../../reduxHelper';

import { UserInfo } from '../../../api/user';

export const REFRESH_USER_INFO_FLAG = 'REFRESH_USER_INFO_FLAG';
export const REMOVE_REFRESH_USER_INFO_FLAG = 'REMOVE_REFRESH_USER_INFO_FLAG';
export const SET_USER_INFO = 'SET_USER_INFO';
export const REMOVE_USER_INFO = 'REMOVE_USER_INFO';

export function refreshUserInfoFlag(): ReduxAction<boolean> {
  return {
    type: REFRESH_USER_INFO_FLAG,
  };
}

export function removeRefreshUserInfoFlag(): ReduxAction<boolean> {
  return {
    type: REMOVE_REFRESH_USER_INFO_FLAG,
  };
}

export function setUserInfo(info: UserInfo): ReduxAction<UserInfo> {
  return {
    type: SET_USER_INFO,
    payload: info,
  };
}

export function removePreCond() {
  return {
    type: REMOVE_USER_INFO,
  };
}
