import React from 'react';

import { Row, Col } from 'antd';
import { useWindowSize } from 'react-use';

import Label from '../Label';
import { isMobileMode } from '../../../../utils/helper';
import Messages from '../../../../utils/messages';

import styles from './index.module.scss';

interface inCommonFormRowLayoutProps {
  id?: string;
  label?: string | React.ReactNode;
  editable?: boolean;
  labelSpanOfPC?: number;
  labelSpanOfMobile?: number;
  mustInput?: boolean;
  children?: React.ReactNode;
  errorMsg?: string | string[];
}
const CommonFormRowLayout: React.FC<inCommonFormRowLayoutProps> = (
  props: inCommonFormRowLayoutProps
) => {
  const { width } = useWindowSize();

  const {
    id,
    label,
    editable,
    labelSpanOfPC,
    labelSpanOfMobile,
    mustInput,
    children,
    errorMsg,
  } = props;

  let labelSpan = typeof labelSpanOfPC === 'undefined' ? 5 : labelSpanOfPC;
  if (isMobileMode(width)) {
    labelSpan =
      typeof labelSpanOfMobile === 'undefined' ? 24 : labelSpanOfMobile;
  }
  if (!label) {
    labelSpan = 0;
  }

  const contentSpan = labelSpan === 24 ? 24 : 24 - labelSpan;

  let labelNode = null;
  if (typeof label === 'string') {
    labelNode = (
      <span className={styles.labelWrapper}>
        <span>{label}</span>
        {editable && mustInput && (
          <Label
            value={Messages['management.commom.validator.mustinput']}
            type="mustInput"
            className={styles.mustInputLabel}
          />
        )}
      </span>
    );
  } else if (label) {
    labelNode = (
      <span className={styles.labelWrapper}>
        <span className={styles.reactNodelabelWrapper}>
          {label}
          {editable && mustInput && (
            <Label
              value={Messages['management.commom.validator.mustinput']}
              type="mustInput"
              className={styles.mustInputLabel}
            />
          )}
        </span>
      </span>
    );
  }

  const getErrorMsg = (editable?: boolean, errors?: string | string[]) => {
    if (!editable || !errors) {
      return null;
    }
    if (typeof errors === 'string') {
      return <div className={styles.errorMsg}>{errors}</div>;
    }
    if (Array.isArray(errors) && errors.length > 0) {
      return (
        <div className={styles.errorMsg}>
          {errors.map((msg) => {
            return <div>{msg}</div>;
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <Row id={id}>
      {labelSpan > 0 && <Col span={labelSpan}>{labelNode}</Col>}
      <Col span={contentSpan}>
        {children}
        {getErrorMsg(editable, errorMsg)}
      </Col>
    </Row>
  );
};

export default CommonFormRowLayout;
